import PropTypes from 'prop-types';
import React from 'react';
import Box from '@shoreag/base/Box';
import { get } from 'lodash';
import { CARD_GAP_DEFAULT, CARD_TYPES, CHARTS } from '../chart-config';

const ChartCardContainer = ({ wrapperSx, innerSx, children, placeHolder }) => {
  const { defaultSize: cardWidth, ratio } = CARD_TYPES.landscape;
  const cardHolderStyle = {
    display: 'flex',
    flexDirection: 'column',
    p: `${CARD_GAP_DEFAULT / 2}px`,
    width: cardWidth,
    ...innerSx,
  };

  return (
    <Box
      sx={{
        alignItems: 'flex-start',
        display: 'flex',
        flexWrap: 'wrap',
        m: `-${CARD_GAP_DEFAULT / 2}px`,
        ...wrapperSx,
      }}
    >
      {React.Children.map(children, (child, index) => {
        const chartName = get(child, 'props.chartData.chartName', '');
        const { defaultSize: chartSize } = get(
          CHARTS[chartName],
          'sizeRatio',
          CARD_TYPES.landscape
        );

        return (
          <Box
            key={index}
            sx={{
              ...cardHolderStyle,
              width: chartSize,
            }}
          >
            {child}
          </Box>
        );
      })}
      {children.length === 0 && placeHolder && (
        <Box sx={{ ...cardHolderStyle, aspectRatio: ratio }}>{placeHolder}</Box>
      )}
    </Box>
  );
};

ChartCardContainer.propTypes = {
  children: PropTypes.node.isRequired,
  innerSx: PropTypes.shape({}),
  placeHolder: PropTypes.node,
  wrapperSx: PropTypes.shape({}),
};

ChartCardContainer.defaultProps = {
  innerSx: {},
  placeHolder: null,
  wrapperSx: {},
};

export default ChartCardContainer;
