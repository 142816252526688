import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

export const CARD_GAP_DEFAULT = 16;

export const DARKMODE_DEFAULT_OPTIONS = {
  backgroundColor: 'transparent',
  legend: {
    textStyle: {
      color: 'white',
    },
  },
  textStyle: {
    color: 'white',
  },
};

export const CARD_SIZES = [
  ['100%', null, null, null, '33.33%'],
  ['100%', null, null, null, '50%'],
  ['100%', null, null, null, '66.66%'],
  '100%',
];

export const CARD_TYPES = {
  landscape: {
    defaultSize: CARD_SIZES[1],
    ratio: '2 / 1',
  },
  square: {
    defaultSize: CARD_SIZES[1],
    ratio: '1 / 1',
  },
};

export const CHART_TYPES = {
  basicScatter: 'basicScatter',
  scatterToBar: 'scatterToBar',
  stackedHorizontalBar: 'stackedHorizontalBar',
  stackedHorizontalBarWithNegative: 'stackedHorizontalBarWithNegative',
  stackedLine: 'stackedLine',
  sunBrust: 'sunBrust',
  verticalBarChart: 'verticalBarChart',
};

export const CARD_SIZE_AND_RATIO = {
  [CHART_TYPES.basicScatter]: CARD_TYPES.landscape,
  [CHART_TYPES.scatterToBar]: CARD_TYPES.landscape,
  [CHART_TYPES.stackedHorizontalBar]: CARD_TYPES.landscape,
  [CHART_TYPES.stackedHorizontalBarWithNegative]: CARD_TYPES.landscape,
  [CHART_TYPES.stackedLine]: CARD_TYPES.landscape,
  [CHART_TYPES.sunBrust]: CARD_TYPES.square,
  [CHART_TYPES.verticalBarChart]: CARD_TYPES.landscape,
};

export const CHART_COLORS_SERIES = () => {
  const theme = useContext(ThemeContext);
  const visualizationsColors = [...theme.colors.visualizations];
  visualizationsColors.splice(0, 4);
  return [
    theme.colors.accent,
    theme.colors.success,
    theme.colors.warning,
    theme.colors.accentSecondary,
    theme.colors.greenLight,
    theme.colors.limeYellow,
    theme.colors.accentLight,
    theme.colors.error,
    theme.colors.accentSecondaryDark,
    ...visualizationsColors,
  ];
};

export const CHARTS = {
  distributionOfErrorsByErrorType: {
    allowEdit: true,
    chartType: CHART_TYPES.sunBrust,
    disabled: false,
    esQuery: {
      aggs: ['partnerId', 'stepName', 'stepError'],
    },
    getColorSeries: () => CHART_COLORS_SERIES(),
    label: 'Distribution of Errors by Error type',
    multiplePartner: false,
    sizeRatio: CARD_SIZE_AND_RATIO.sunBrust,
  },
  enrollmentRecordTypeDistribution: {
    allowEdit: true,
    chartType: CHART_TYPES.sunBrust,
    disabled: false,
    esQuery: {
      aggs: ['transactionType', 'productCode', 'customerNumber'],
    },
    getColorSeries: () => {
      const theme = useContext(ThemeContext);
      return [theme.colors.success, theme.colors.warning, theme.colors.error];
    },
    label: 'Enrollment Record Type Distribution',
    multiplePartner: false,
    sizeRatio: CARD_SIZE_AND_RATIO.sunBrust,
  },
  errorCountAndSuccessCountByPartner: {
    allowEdit: true,
    chartType: CHART_TYPES.stackedHorizontalBar,
    disabled: false,
    esQuery: {
      aggs: ['status'],
      term: 'partnerId',
    },
    getColorSeries: () => {
      const theme = useContext(ThemeContext);
      return [
        theme.colors.accent,
        theme.colors.success,
        theme.colors.error,
        theme.colors.gray5,
      ];
    },
    label: 'Error Count And Success Count By Partner',
    multiplePartner: true,
    sizeRatio: CARD_SIZE_AND_RATIO.stackedHorizontalBar,
  },
  errorCountbyEmployeeIdentifier: {
    allowEdit: true,
    chartType: CHART_TYPES.scatterToBar,
    disabled: false,
    esQuery: {
      aggs: ['rowNumber'],
      term: 'customerNumber',
    },
    getColorSeries: () => CHART_COLORS_SERIES(),
    label: 'Error Count by Employee Identifier',
    multiplePartner: false,
    sizeRatio: CARD_SIZE_AND_RATIO.scatterToBar,
  },
  recordsTransformed: {
    allowEdit: true,
    chartType: CHART_TYPES.stackedHorizontalBarWithNegative,
    disabled: false,
    esQuery: {
      aggs: ['passedRecords', 'failedRecords'],
      term: 'partnerId',
    },
    getColorSeries: () => {
      const theme = useContext(ThemeContext);
      return [theme.colors.success, theme.colors.error];
    },
    label: 'Records Transformed (Not in Good Order / In Good Order)',
    multiplePartner: true,
    sizeRatio: CARD_SIZE_AND_RATIO.stackedHorizontalBarWithNegative,
  },
  stepLengthVsTotalProcessingTime: {
    allowEdit: true,
    chartType: CHART_TYPES.stackedHorizontalBar,
    disabled: false,
    esQuery: {
      aggs: [
        'stepProcessingTime.stepName',
        'stepProcessingTime.processingTime',
      ],
      term: 'partnerId',
    },
    getColorSeries: () => CHART_COLORS_SERIES(),
    label: 'Step length vs Total processing time',
    multiplePartner: true,
    sizeRatio: CARD_SIZE_AND_RATIO.stackedHorizontalBar,
  },
  totalProcessingTimeOverTime: {
    allowEdit: true,
    chartType: CHART_TYPES.stackedLine,
    disabled: false,
    esQuery: {
      aggs: ['totalProcessingTime'],
      term: 'partnerId',
    },
    getColorSeries: () => CHART_COLORS_SERIES(),
    label: 'Total Processing Time Over Time',
    multiplePartner: true,
    sizeRatio: CARD_SIZE_AND_RATIO.stackedLine,
  },
  tradingPartnersFileCount: {
    allowEdit: true,
    chartType: CHART_TYPES.stackedHorizontalBar,
    disabled: false,
    esQuery: {
      aggs: ['format'],
      term: 'partnerId',
    },
    getColorSeries: () => CHART_COLORS_SERIES(),
    label: 'Trading Partners File Count',
    multiplePartner: true,
    sizeRatio: CARD_SIZE_AND_RATIO.stackedHorizontalBar,
  },
};

export const SAMPLE_CHART_DATA = {
  distributionOfErrorsByErrorType: {
    chartOption: {
      series: {
        data: [
          {
            children: [
              {
                children: [
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.accent),
                    // },
                    name: 'sv001',
                    value: 2,
                  },
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.accent),
                    // },
                    name: 'sv002',
                    value: 5,
                  },
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.accent),
                    // },
                    name: 'sv003',
                    value: 1,
                  },
                ],
                // itemStyle: {
                //   color: lighten(0.1, theme.colors.accent),
                // },
                name: 'Schema verification',
                value: 8,
              },
              {
                children: [
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.accent),
                    // },
                    name: 'vm001',
                    value: 1,
                  },
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.accent),
                    // },
                    name: 'vm002',
                    value: 1,
                  },
                ],
                // itemStyle: {
                //   color: lighten(0.1, theme.colors.accent),
                // },
                name: 'Value mapper',
                value: 2,
              },
              {
                children: [
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.accent),
                    // },
                    name: 'cm001',
                    value: 2,
                  },
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.accent),
                    // },
                    name: 'cm002',
                    value: 5,
                  },
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.accent),
                    // },
                    name: 'cm003',
                    value: 3,
                  },
                ],
                // itemStyle: {
                //   color: lighten(0.1, theme.colors.accent),
                // },
                name: 'Column mapper',
                value: 10,
              },
            ],
            // itemStyle: {
            //   color: theme.colors.accent,
            // },
            name: 'Vox Machina',
            value: 20,
          },
        ],
        type: 'sunburst',
      },
    },
  },
  enrollmentRecordTypeDistribution: {
    chartOption: {
      series: {
        data: [
          {
            children: [
              {
                children: [
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.error),
                    // },
                    name: 'eg001',
                    value: 5,
                  },
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.error),
                    // },
                    name: 'eg002',
                    value: 4,
                  },
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.error),
                    // },
                    name: 'eg003',
                    value: 4,
                  },
                ],
                // itemStyle: {
                //   color: lighten(0.1, theme.colors.error),
                // },
                name: 'Accident',
                value: 13,
              },
              {
                children: [
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.error),
                    // },
                    name: 'eg001',
                    value: 4,
                  },
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.error),
                    // },
                    name: 'eg002',
                    value: 1,
                  },
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.error),
                    // },
                    name: 'eg003',
                    value: 2,
                  },
                ],
                // itemStyle: {
                //   color: lighten(0.1, theme.colors.error),
                // },
                name: 'CI',
                value: 7,
              },
              {
                children: [
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.error),
                    // },
                    name: 'eg001',
                    value: 4,
                  },
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.error),
                    // },
                    name: 'eg002',
                    value: 6,
                  },
                ],
                // itemStyle: {
                //   color: lighten(0.1, theme.colors.error),
                // },
                name: 'HI',
                value: 10,
              },
            ],
            // itemStyle: { color: theme.colors.error },
            name: 'Cancelation',
            value: 30,
          },
          {
            children: [
              {
                children: [
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.accent),
                    // },
                    name: 'eg001',
                    value: 2,
                  },
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.accent),
                    // },
                    name: 'eg002',
                    value: 5,
                  },
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.accent),
                    // },
                    name: 'eg003',
                    value: 6,
                  },
                ],
                // itemStyle: {
                //   color: lighten(0.1, theme.colors.accent),
                // },
                name: 'Accident',
                value: 13,
              },
              {
                children: [
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.accent),
                    // },
                    name: 'eg001',
                    value: 7,
                  },
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.accent),
                    // },
                    name: 'eg002',
                    value: 5,
                  },
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.accent),
                    // },
                    name: 'eg003',
                    value: 5,
                  },
                ],
                // itemStyle: {
                //   color: lighten(0.1, theme.colors.accent),
                // },
                name: 'CI',
                value: 17,
              },
              {
                children: [
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.accent),
                    // },
                    name: 'eg001',
                    value: 20,
                  },
                ],
                // itemStyle: {
                //   color: lighten(0.1, theme.colors.accent),
                // },
                name: 'HI',
                value: 20,
              },
            ],
            // itemStyle: {
            //   color: theme.colors.accent,
            // },
            name: 'Enrollment',
            value: 50,
          },
          {
            children: [
              {
                children: [
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.success),
                    // },
                    name: 'eg001',
                    value: 2,
                  },
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.success),
                    // },
                    name: 'eg002',
                    value: 3,
                  },
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.success),
                    // },
                    name: 'eg003',
                    value: 2,
                  },
                ],
                // itemStyle: {
                //   color: lighten(0.1, theme.colors.success),
                // },
                name: 'Accident',
                value: 7,
              },
              {
                children: [
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.success),
                    // },
                    name: 'eg002',
                    value: 1,
                  },
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.success),
                    // },
                    name: 'eg003',
                    value: 6,
                  },
                ],
                // itemStyle: {
                //   color: lighten(0.1, theme.colors.success),
                // },
                name: 'CI',
                value: 7,
              },
              {
                children: [
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.success),
                    // },
                    name: 'eg001',
                    value: 2,
                  },
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.success),
                    // },
                    name: 'eg002',
                    value: 2,
                  },
                  {
                    // itemStyle: {
                    //   color: lighten(0.2, theme.colors.success),
                    // },
                    name: 'eg003',
                    value: 2,
                  },
                ],
                // itemStyle: {
                //   color: lighten(0.1, theme.colors.success),
                // },
                name: 'HI',
                value: 6,
              },
            ],
            // itemStyle: {
            //   color: theme.colors.success,
            // },
            name: 'Endourcement',
            value: 20,
          },
        ],
        type: 'sunburst',
      },
    },
  },
  errorCountAndSuccessCountByPartner: {
    chartOption: {
      // color: [theme.colors.accent, theme.colors.error, theme.colors.success],
      series: [
        {
          data: [320, 302, 301, 334, 390, 330, 320],
          name: 'Progress',
        },
        {
          data: [120, 132, 101, 134, 90, 230, 210],
          name: 'Failed',
        },
        {
          data: [220, 182, 191, 234, 290, 330, 310],
          name: 'Success',
        },
      ],
      yAxis: {
        data: ['Vox Machina', 'Gom Joint', 'Hartford', 'Partner one', 'Farday'],
      },
    },
  },
  errorCountbyEmployeeIdentifier: {
    chartOption: {
      rawData: {
        EG001: {
          data: [
            [10, 0],
            [15, 4],
            [20, 15],
            [25, 40],
            [30, 90],
            [35, 65],
            [40, 25],
            [45, 18],
            [50, 10],
            [55, 0],
          ],
        },
        EG002: {
          data: [
            [50, 0],
            [55, 10],
            [60, 20],
            [65, 45],
            [70, 110],
            [75, 95],
            [80, 54],
            [85, 30],
            [90, 10],
            [95, 0],
          ],
        },
        EG003: {
          data: [
            [100, 0],
            [110, 18],
            [200, 28],
            [230, 58],
            [300, 138],
            [340, 105],
            [400, 50],
            [410, 28],
            [500, 8],
            [550, 0],
          ],
        },
      },
    },
  },
  recordsTransformed: {
    chartOption: {
      legend: {
        data: ['Not in Good Order', 'In Good Order'],
      },
      series: [
        {
          data: [320, 302, 341, 374, 390],
          name: 'In Good Order',
        },
        {
          data: [-120, -132, -101, -134, -190],
          name: 'Not in Good Order',
        },
      ],
      yAxis: {
        data: ['Vox Machina', 'Gom Joint', 'Hartford', 'Partner one', 'Farday'],
      },
    },
  },
  stepLengthVsTotalProcessingTime: {
    chartOption: {
      series: [
        {
          data: [320, 302, 0, 330, 320],
          name: 'Schema Varification',
        },
        {
          data: [120, 132, 101, 230, 210],
          name: 'Value mapper',
        },
        {
          data: [220, 182, 0, 290, 330],
          name: 'Column Mapper',
        },
        {
          data: [150, 212, 201, 154, 190],
          name: 'LDEX converter',
        },
        {
          data: [820, 934, 0, 1330, 1320],
          name: 'Normalizer',
        },
      ],
      yAxis: {
        data: ['Vox Machina', 'Gom Joint', 'Hartford', 'Partner one', 'Farday'],
      },
    },
  },
  totalProcessingTimeOverTime: {
    chartOption: {
      legend: {
        data: ['Vox Machina', 'Gom Joint', 'Hartford', 'Partner one', 'Farday'],
      },
      series: [
        {
          data: [4, 2, '', ''],
          name: 'Vox Machina',
        },
        {
          data: ['', '', 1, 2],
          name: 'Gom Joint',
        },
      ],
      xAxis: {
        data: ['2', '1177', '6975', '6976'],
      },
    },
  },
  tradingPartnersFileCount: {
    chartOption: {
      series: [
        {
          data: [320, 302, 301, 334, 390, 330, 320],
          name: 'Vox Machina',
        },
        {
          data: [120, 132, 101, 134, 90, 230, 210],
          name: 'Gom Joint',
        },
        {
          data: [20, 122, 121, 214, 190, 230, 110],
          name: 'Hartford',
        },
        {
          data: [110, 121, 241, 230, 140, 120, 120],
          name: 'Partner One',
        },
        {
          data: [110, 242, 241, 164, 140, 120, 120],
          name: 'Farday',
        },
      ],
      yAxis: {
        data: ['CSV', 'XLS', 'TXT', 'JSON', 'XML'],
        type: 'category',
      },
    },
  },
};
