import React from 'react';
import PropTypes from 'prop-types';
import ECharts from '../ECharts';

const VerticalBarChart = ({ options }) => {
  const updatedOptions = {
    tooltip: {
      axisPointer: {
        type: 'shadow',
      },
      trigger: 'axis',
    },
    yAxis: {
      type: 'value',
    },
    ...options,
  };
  const customizedOptions = {
    ...updatedOptions,
    series: options.series.map((i) => ({
      ...i,
      emphasis: {
        focus: 'series',
      },
      label: {
        show: true,
      },
      stack: 'total',
      type: 'bar',
    })),
    xAxis: {
      data: options.xAxis.data,
      type: 'category',
    },
  };

  return <ECharts option={customizedOptions} />;
};

VerticalBarChart.propTypes = {
  options: PropTypes.shape({
    series: PropTypes.arrayOf(PropTypes.shape({})),
    xAxis: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

VerticalBarChart.defaultProps = {
  options: {},
};

export default VerticalBarChart;
