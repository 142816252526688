import PropTypes from 'prop-types';
import React from 'react';
import Box from '@shoreag/base/Box';
import ToolbarButton from '../../ToolbarButton';

const AddChartPlaceHolder = ({ onAddNewChart }) => {
  return (
    <Box
      onClick={() => onAddNewChart()}
      sx={{
        '&:hover': {
          bg: 'grays.2',
        },
        alignItems: 'center',
        bg: 'none',
        borderColor: 'grays.3',
        borderRadius: 2,
        borderStyle: 'dashed',
        borderWidth: 2,
        color: 'grays.3',
        cursor: 'pointer',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        p: 5,
      }}
    >
      <ToolbarButton bg="grays.4" icon="plus" label="Add Chart" />
    </Box>
  );
};

AddChartPlaceHolder.propTypes = {
  onAddNewChart: PropTypes.func,
  sx: PropTypes.shape({}),
};

AddChartPlaceHolder.defaultProps = {
  onAddNewChart: () => null,
  sx: {},
};

export default AddChartPlaceHolder;
