import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useQuery } from 'react-apollo';
import { AuthContext } from '@shoreag/auth';
import { parse, stringify } from 'query-string';
import stripTypename from '@shoreag/helpers/strip-typename';
import { lighten } from 'polished';
import ECharts from '../ECharts';
import ChartCard from '../../ChartCard';
import {
  CARD_TYPES,
  CHARTS,
  DARKMODE_DEFAULT_OPTIONS,
} from '../../chart-config';
import dashboardConstants from '../../../../utilities/dashboard-constants';
import enrollmentRecordTypeDistributionQuery from '../../../../graphql/queries/enrollment-record-type-distribution-analytics.gql';
import distributionOfErrorsByErrorTypeQuery from '../../../../graphql/queries/distribution-of-errors-by-error-type-analytics.gql';
import partnerQuery from '../../../../graphql/queries/all-partner.gql';
import PillsGroup from '../../../PillsGroup';
import { UUID_PREFIXES } from '../../../../utilities/constants';

function convertToNested(input) {
  const root = { children: [], name: '', value: 0 };

  input.forEach((item) => {
    let current = root;
    item.label.forEach((label) => {
      let child = current.children.find((c) => c.name === label);
      if (!child) {
        child = {
          children: [],
          name: label,
          value: 0,
        };
        current.children.push(child);
      }
      current = child;
    });
    current.value += item.value;
  });

  calculateParentValues(root);

  return root.children;
}

function calculateParentValues(node) {
  if (node.children.length === 0) {
    return node.value;
  }

  // eslint-disable-next-line no-param-reassign
  node.value = node.children.reduce(
    (total, child) => total + calculateParentValues(child),
    0
  );

  return node.value;
}

function addItemStyle(obj, colorSeries) {
  let rootIndex = 0;

  function traverse(node, parentLevel = 0) {
    const level = parentLevel + 1;
    // eslint-disable-next-line no-param-reassign
    node.itemStyle = {
      color: lighten(
        Number.parseInt(level - 1, 10) / 10,
        colorSeries[rootIndex]
      ),
    };

    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => {
        traverse(child, level);
      });
    }
  }

  obj.forEach((rootNode, index) => {
    rootIndex = index;
    traverse(rootNode);
  });

  return obj;
}

const SunBrustChart = ({ chartData, onRemove, onEdit, darkMode, location }) => {
  const { user } = useContext(AuthContext);
  const { chartName, partner } = chartData;
  const { label, esQuery, getColorSeries, allowEdit } = CHARTS[chartName];
  const { ratio } = get(CHARTS[chartName], 'sizeRatio', CARD_TYPES.landscape);
  const { aggs } = esQuery;

  const { data: allPartnersData, loading: allPartnersLoading } = useQuery(
    partnerQuery
  );

  const CHART_QUERY_CONSTANT = {
    distributionOfErrorsByErrorType: {
      atrribute: 'distributionOfErrorsByErrorTypeAnalytics',
      query: distributionOfErrorsByErrorTypeQuery,
    },
    enrollmentRecordTypeDistribution: {
      atrribute: 'enrollmentRecordTypeDistributionAnalytics',
      query: enrollmentRecordTypeDistributionQuery,
    },
  };

  const isDistributionOfErrorsByErrorType =
    chartName === 'distributionOfErrorsByErrorType';

  const searchFilters = stringify({
    filters: stringify({
      ...parse(get(parse(location.search), 'filters', {})),
      partner: [partner[0].id],
    }),
  });

  const { filters } = dashboardConstants.getCriterias({
    search: searchFilters,
    type: 'analytics_charts',
  });

  const analyticsSearchBody = dashboardConstants.getQuery({
    filters,
    user,
  });

  const { data, loading } = useQuery(CHART_QUERY_CONSTANT[chartName].query, {
    fetchPolicy: 'network-only',
    pollInterval: 5000,
    variables: {
      fields: aggs,
      searchBody: JSON.stringify(analyticsSearchBody),
    },
  });
  const { data: analyticsData } = get(
    data,
    CHART_QUERY_CONSTANT[chartName].atrribute,
    {
      data: [],
    }
  );
  let customizedOptions = {};
  const notData = !loading && analyticsData.length === 0;
  if (!loading && !allPartnersLoading) {
    let updatedAnalyticsData = [...analyticsData];
    if (isDistributionOfErrorsByErrorType) {
      updatedAnalyticsData = analyticsData.map((i) => ({
        ...i,
        label: i.label.map((l) => {
          if (l.includes(UUID_PREFIXES.PARTNER)) {
            return get(
              get(allPartnersData, 'allPartner', []).find((p) => p.id === l),
              'partnerName',
              l
            );
          }
          return l;
        }),
      }));
    }
    const options = {
      series: {
        data: addItemStyle(
          convertToNested(stripTypename(updatedAnalyticsData)),
          getColorSeries()
        ),
        type: 'sunburst',
      },
    };

    const defaultOptions = {
      ...(darkMode ? DARKMODE_DEFAULT_OPTIONS : {}),
    };

    const updatedOptions = {
      ...defaultOptions,
      ...options,
      series: { ...options.series, radius: ['0%', '100%'] },
    };

    customizedOptions = {
      ...updatedOptions,
    };
  }

  let content = <ECharts option={customizedOptions} />;
  if (notData) {
    content = 'No data found!';
  }

  return (
    <ChartCard
      allowEdit={allowEdit}
      bodySx={{ aspectRatio: ratio }}
      darkMode={darkMode}
      footer={
        <PillsGroup
          data={partner.map((p) => ({ label: p.partnerName }))}
          pillSx={{
            '&:hover': { bg: 'accentExtraLight', color: 'accent' },
            bg: 'accentExtraLight',
            color: 'accent',
          }}
          viewOnly
        />
      }
      label={label}
      loading={loading || allPartnersLoading}
      onEdit={onEdit}
      onRemove={onRemove}
    >
      {content}
    </ChartCard>
  );
};

SunBrustChart.propTypes = {
  chartData: PropTypes.shape({
    chartName: PropTypes.string,
    partner: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
  }).isRequired,
  darkMode: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
};

SunBrustChart.defaultProps = {
  darkMode: false,
  onEdit: () => null,
  onRemove: () => null,
};

export default SunBrustChart;
