import PropTypes from 'prop-types';
import React from 'react';
import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import { capitalCase } from 'change-case';
import Spinner from '@shoreag/base/Spinner';
import Button from '@shoreag/base/Button';
import ItemGroups from '../../ItemGroups';
import ResponsiveEllipsis from '../../ResponsiveEllipsis';

const ChartCard = ({
  allowEdit,
  bodySx,
  label,
  darkMode,
  onRemove,
  onEdit,
  children,
  footer,
  footerSx,
  loading,
}) => {
  const actionStyled = {
    '&:hover': {
      opacity: 1,
    },
    fontSize: 2,
    opacity: 0.8,
  };
  const chartLabel = capitalCase(label);
  return (
    <Card
      sx={{
        bg: darkMode ? 'primary' : 'white',
        display: 'flex',
        flex: 'none',
        flexDirection: 'column',
        height: '100%',
        mt: 0,
        pb: 3,
        width: '100%',
      }}
    >
      <Box sx={{ display: 'flex', mb: 6 }}>
        <Box
          as="h3"
          sx={{ color: darkMode ? 'white' : '', flex: 'auto', mr: 4 }}
        >
          <ResponsiveEllipsis text={chartLabel} title={chartLabel} />
        </Box>
        <ItemGroups separator="|" sx={{ flex: 'none' }}>
          {allowEdit && (
            <Button onClick={() => onEdit()} simple sx={{ ...actionStyled }}>
              Edit
            </Button>
          )}
          <Button
            onClick={() => onRemove()}
            simple
            sx={{ ...actionStyled, color: 'error' }}
          >
            Remove
          </Button>
        </ItemGroups>
      </Box>
      <Box sx={{ flex: 'auto', ...bodySx }}>
        {loading ? <Spinner /> : children}
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          minHeight: '30px',
          width: '100%',
          ...footerSx,
        }}
      >
        {footer}
      </Box>
    </Card>
  );
};

ChartCard.propTypes = {
  allowEdit: PropTypes.bool,
  bodySx: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  darkMode: PropTypes.bool,
  footer: PropTypes.node,
  footerSx: PropTypes.shape({}),
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
};

ChartCard.defaultProps = {
  allowEdit: false,
  bodySx: {},
  darkMode: false,
  footer: null,
  footerSx: {},
  loading: false,
  onEdit: () => null,
  onRemove: () => null,
};

export default ChartCard;
