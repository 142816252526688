import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useQuery } from 'react-apollo';
import { AuthContext } from '@shoreag/auth';
import { parse, stringify } from 'query-string';
import Button from '@shoreag/base/Button';
import PillsGroup from '../../../PillsGroup';
import ECharts from '../ECharts';
import ChartCard from '../../ChartCard';
import {
  CARD_TYPES,
  CHARTS,
  CHART_TYPES,
  DARKMODE_DEFAULT_OPTIONS,
} from '../../chart-config';
import dashboardConstants from '../../../../utilities/dashboard-constants';
import errorCountbyEmployeeIdentifierQuery from '../../../../graphql/queries/error-count-by-employee-Identifier-analytics.gql';
import setDashboardChartAxisLabel from '../../../../utilities/set-dashboard-chart-axis-label';

const ScatterToBarChart = ({
  chartData,
  onRemove,
  onEdit,
  darkMode,
  defaultBarView,
  allowToggleBarView,
  location,
}) => {
  const { user } = useContext(AuthContext);
  const [barView, setBarView] = useState(defaultBarView);

  const calculateAverage = (data, dim) => {
    return (
      data.reduce((total, item) => {
        return total + item[dim];
      }, 0) / data.length
    );
  };

  const { chartName, partner } = chartData;
  const { label, esQuery, allowEdit, getColorSeries } = CHARTS[chartName];
  const { ratio } = get(CHARTS[chartName], 'sizeRatio', CARD_TYPES.landscape);
  const { term, aggs } = esQuery;

  const CHART_QUERY_CONSTANT = {
    errorCountbyEmployeeIdentifier: {
      atrribute: 'errorCountbyEmployeeIdentifierAnalytics',
      query: errorCountbyEmployeeIdentifierQuery,
    },
  };

  const searchFilters = stringify({
    filters: stringify({
      ...parse(get(parse(location.search), 'filters', {})),
      partner: [partner[0].id],
    }),
  });

  const { filters } = dashboardConstants.getCriterias({
    search: searchFilters,
    type: 'analytics_charts',
  });

  const analyticsSearchBody = dashboardConstants.getQuery({
    filters,
    user,
  });

  const { data, loading, error } = useQuery(
    CHART_QUERY_CONSTANT[chartName].query,
    {
      fetchPolicy: 'network-only',
      pollInterval: 5000,
      variables: {
        field: term,
        fields: aggs,
        searchBody: JSON.stringify(analyticsSearchBody),
      },
    }
  );

  const { data: analyticsData } = get(
    data,
    CHART_QUERY_CONSTANT[chartName].atrribute,
    []
  );

  function countOccurrences(arr) {
    const counts = new Map();
    arr.forEach((element) => {
      counts.set(element, (counts.get(element) || 0) + 1);
    });
    return Array.from(counts.entries());
  }

  let customizedOptions = {
    [CHART_TYPES.basicScatter]: {},
    [CHART_TYPES.verticalBarChart]: {},
  };
  const notData = !loading && analyticsData?.length === 0;
  if (!loading && !error) {
    let rawData = {};
    analyticsData.forEach((i) => {
      rawData = {
        ...rawData,
        [i.label]: { data: countOccurrences(i.value.map((v) => v.value)) },
      };
    });
    const defaultOptions = {
      color: getColorSeries(),
      tooltip: {
        axisPointer: {
          type: 'shadow',
        },
        trigger: 'axis',
      },
      yAxis: {
        type: 'value',
      },
      ...(darkMode ? DARKMODE_DEFAULT_OPTIONS : {}),
    };

    const seriesKey = Object.keys(rawData).map((key) => key);
    const updatedScatterChartOptions = {
      ...defaultOptions,
      series: seriesKey.map((key, index) => {
        const { data } = rawData[key];
        return {
          data,
          dataGroupId: key,
          id: key,
          itemStyle: {
            color: getColorSeries()[index],
          },
          type: 'scatter',
          universalTransition: {
            delay: () => {
              return Math.random() * 400;
            },
            enabled: true,
          },
        };
      }),
      tooltip: {
        formatter: (params) =>
          `Employees: ${params.data[0]}<br/>Errors: ${Math.round(
            params.data[1]
          )}`,
      },
      xAxis: {
        axisLine: { show: false },
        axisTick: {
          show: true,
        },
        name: 'Errors',
        nameTextStyle: { fontSize: '10px' },
        scale: true,
      },
      yAxis: {
        axisLabel: setDashboardChartAxisLabel(),
        axisLine: { show: false },
        axisTick: {
          show: true,
        },
        interval: 1,
        // max: 5, // Set maximum value for y-axis if needed
        min: 0,
        name: 'Employees',
        nameTextStyle: { fontSize: '10px' },
        scale: true,
      },
    };
    const updatedBarChartOptions = {
      ...defaultOptions,
      legend: {},
      series: [
        {
          data: Object.keys(rawData).map((key, index) => {
            const { data } = rawData[key];
            const getAverage = calculateAverage(data, 1);
            return {
              emphasis: {
                focus: 'series',
              },
              groupId: key,
              itemStyle: { color: defaultOptions.color[index] },
              label: {
                show: true,
              },
              stack: 'total',
              type: 'bar',
              value: getAverage ? Math.round(getAverage * 100) / 100 : '',
            };
          }),
          id: 'total',
          type: 'bar',
          universalTransition: {
            delay: () => {
              return Math.random() * 400;
            },
            enabled: true,
            seriesKey,
          },
        },
      ],
      xAxis: {
        axisLine: { show: false },
        axisTick: {
          show: true,
        },
        data: seriesKey,
        name: 'Employer\ngroup',
        nameTextStyle: { fontSize: '10px' },
        type: 'category',
      },
      yAxis: {
        axisLabel: setDashboardChartAxisLabel(),
        axisLine: { show: false },
        axisTick: {
          show: true,
        },
        interval: 1,
        max: 5, // Set maximum value for y-axis
        min: 0, // Set minimum value for y-axis
        name: 'Avg. Error',
        nameTextStyle: { fontSize: '10px' },
      },
    };
    customizedOptions = {
      [CHART_TYPES.basicScatter]: updatedScatterChartOptions,
      [CHART_TYPES.verticalBarChart]: updatedBarChartOptions,
    };
  }

  const toggleLabel = {
    [CHART_TYPES.basicScatter]: 'Bar View',
    [CHART_TYPES.verticalBarChart]: 'Scatter View',
  };

  const ToggleButton = () => (
    <Button
      onClick={() => {
        setBarView(
          barView === CHART_TYPES.basicScatter
            ? CHART_TYPES.verticalBarChart
            : CHART_TYPES.basicScatter
        );
      }}
      sx={{
        fontSize: 1,
        height: 'auto',
        px: 2,
        py: 2,
      }}
      variant="buttons.secondary"
    >
      {toggleLabel[barView]}
    </Button>
  );

  let content = <ECharts option={customizedOptions[barView]} />;
  if (notData) {
    content = 'No data found!';
  }
  if (error) {
    content = 'Somthing went wrong!';
  }

  return (
    <ChartCard
      allowEdit={allowEdit}
      bodySx={{ aspectRatio: ratio }}
      darkMode={darkMode}
      footer={
        <>
          <PillsGroup
            data={partner.map((p) => ({ label: p.partnerName }))}
            pillSx={{
              '&:hover': { bg: 'accentExtraLight', color: 'accent' },
              bg: 'accentExtraLight',
              color: 'accent',
            }}
            viewOnly
            wrapperSx={{ mr: 'auto' }}
          />
          {allowToggleBarView ? <ToggleButton /> : null}
        </>
      }
      label={label}
      loading={loading}
      onEdit={onEdit}
      onRemove={onRemove}
    >
      {content}
    </ChartCard>
  );
};

ScatterToBarChart.propTypes = {
  allowToggleBarView: PropTypes.bool,
  chartData: PropTypes.shape({
    chartName: PropTypes.string,
    partner: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
  }).isRequired,
  darkMode: PropTypes.bool,
  defaultBarView: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
};

ScatterToBarChart.defaultProps = {
  allowToggleBarView: true,
  darkMode: false,
  defaultBarView: CHART_TYPES.basicScatter,
  onEdit: () => null,
  onRemove: () => null,
};

export default ScatterToBarChart;
