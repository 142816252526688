import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useQuery } from 'react-apollo';
import { AuthContext } from '@shoreag/auth';
import { parse, stringify } from 'query-string';
import ECharts from '../ECharts';
import ChartCard from '../../ChartCard';
import {
  CARD_TYPES,
  CHARTS,
  DARKMODE_DEFAULT_OPTIONS,
} from '../../chart-config';
import dashboardConstants from '../../../../utilities/dashboard-constants';
import partnerQuery from '../../../../graphql/queries/all-partner.gql';
import totalProcessingTimeOverTimeQuery from '../../../../graphql/queries/total-processing-time-over-time-analytics.gql';
import setDashboardChartAxisLabel from '../../../../utilities/set-dashboard-chart-axis-label';

const StackedLinesChart = ({
  chartData,
  onRemove,
  onEdit,
  darkMode,
  location,
}) => {
  const { user } = useContext(AuthContext);

  const { chartName, partner } = chartData;
  const { label, esQuery, getColorSeries, allowEdit } = CHARTS[chartName];
  const { ratio } = get(CHARTS[chartName], 'sizeRatio', CARD_TYPES.landscape);
  const { term, aggs } = esQuery;

  const { data: allPartnersData, loading: allPartnersLoading } = useQuery(
    partnerQuery
  );

  const CHART_QUERY_CONSTANT = {
    totalProcessingTimeOverTime: {
      atrribute: 'totalProcessingTimeOverTimeAnalytics',
      query: totalProcessingTimeOverTimeQuery,
    },
  };

  const searchFilters = stringify({
    filters: stringify({
      ...parse(get(parse(location.search), 'filters', {})),
      partner: partner.map((p) => p.id),
    }),
  });

  const { filters } = dashboardConstants.getCriterias({
    search: searchFilters,
    type: 'analytics_charts',
  });

  const analyticsSearchBody = dashboardConstants.getQuery({
    filters,
    user,
  });

  const { data, loading, error } = useQuery(
    CHART_QUERY_CONSTANT[chartName].query,
    {
      fetchPolicy: 'network-only',
      pollInterval: 5000,
      variables: {
        field: term,
        fields: aggs,
        searchBody: JSON.stringify(analyticsSearchBody),
      },
    }
  );
  const { data: analyticsData } = get(
    data,
    CHART_QUERY_CONSTANT[chartName].atrribute,
    []
  );
  let customizedOptions = {};
  const notData = !loading && analyticsData?.length === 0;

  if (!loading && !allPartnersLoading && !error) {
    const options = {
      legend: {
        data: partner.map((i) => i.partnerName),
      },
      series: analyticsData.map((i) => ({
        data: i.value.map((v) => (v.value !== 0 ? v.value : '')),
        name: get(
          get(allPartnersData, 'allPartner', []).find(
            (ap) => ap.id === i.label
          ),
          'partnerName',
          i.label
        ),
      })),
      xAxis: {
        data: get(analyticsData[0], 'value', []).map((i) => i.label),
      },
    };
    const updatedOptions = {
      color: getColorSeries(),
      tooltip: {
        axisPointer: {
          type: 'shadow',
        },
        trigger: 'axis',
      },
      yAxis: {
        axisLabel: setDashboardChartAxisLabel(),
        axisLine: { show: false },
        axisTick: {
          show: true,
        },
        name: 'Records',
        nameTextStyle: { fontSize: '10px' },
        type: 'value',
      },
      ...(darkMode ? DARKMODE_DEFAULT_OPTIONS : {}),
      ...options,
    };
    customizedOptions = {
      ...updatedOptions,
      legend: {
        data: options.legend.data,
      },
      series: options.series.map((i) => ({
        ...i,
        connectNulls: true,
        type: 'line',
      })),
      xAxis: {
        axisTick: {
          show: true,
        },
        data: options.xAxis.data,
        name: 'Processing\nTime (sec)',
        nameTextStyle: { fontSize: '10px' },
        type: 'category',
      },
    };
  }

  let content = <ECharts option={customizedOptions} />;
  if (notData) {
    content = 'No data found!';
  }
  if (error) {
    content = 'Somthing went wrong!';
  }

  return (
    <ChartCard
      allowEdit={allowEdit}
      bodySx={{ aspectRatio: ratio }}
      darkMode={darkMode}
      label={label}
      loading={loading || allPartnersLoading}
      onEdit={onEdit}
      onRemove={onRemove}
    >
      {content}
    </ChartCard>
  );
};

StackedLinesChart.propTypes = {
  chartData: PropTypes.shape({
    chartName: PropTypes.string,
    partner: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  darkMode: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
};

StackedLinesChart.defaultProps = {
  darkMode: false,
  onEdit: () => null,
  onRemove: () => null,
};

export default StackedLinesChart;
