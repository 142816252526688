import PropTypes from 'prop-types';
import React from 'react';
import ReactECharts from 'echarts-for-react';

const ECharts = ({ option, ...rest }) => {
  return (
    <ReactECharts
      notMerge
      option={{
        grid: {
          bottom: '10%',
        },
        ...option,
      }}
      style={{ height: '100%', width: '100%' }}
      {...rest}
    />
  );
};

ECharts.propTypes = {
  option: PropTypes.shape({}).isRequired,
};

ECharts.defaultProps = {};

export default ECharts;
