import React from 'react';
import PropTypes from 'prop-types';
import ECharts from '../ECharts';

const BasicScatterChart = ({ options }) => {
  const updatedOptions = {
    tooltip: {
      axisPointer: {
        type: 'shadow',
      },
      trigger: 'axis',
    },
    xAxis: {
      scale: true,
    },
    yAxis: {
      scale: true,
    },
    ...options,
  };
  const customizedOptions = {
    ...updatedOptions,
    series: options.series.map((i) => ({
      type: 'scatter',
      ...i,
    })),
  };

  return <ECharts option={customizedOptions} />;
};

BasicScatterChart.propTypes = {
  options: PropTypes.shape({
    series: PropTypes.arrayOf(PropTypes.shape({})),
    xAxis: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

BasicScatterChart.defaultProps = {
  options: {},
};

export default BasicScatterChart;
