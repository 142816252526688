import Box from '@shoreag/base/Box';
import Dropdown from '@shoreag/base/Dropdown';
import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import Button from '@shoreag/base/Button';
import { TooltipBox } from '@shoreag/base/Tooltip';
import Icon from '../../Icon';

const GridButton = ({ sx, icon, index, label, onClick }) => {
  return (
    <Box data-for={`gridToolbar-${index}`} data-tip={label}>
      <Button
        onClick={onClick}
        sx={{
          alignItems: 'center',
          bg: 'white',
          boxShadow: 'none',
          color: 'accent',
          mx: 2,
          px: 4,
          py: 4,
          ...sx,
        }}
        variant="transperent"
      >
        <Icon height="1rem" svg={icon} width="1rem" />
      </Button>
    </Box>
  );
};

const GraphToolbar = ({ appendTools, data, fields, index, name }) => {
  const graphLabelSx = { display: 'none' };
  const graphWrapperSx = { fontSize: 2 };
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        height: '20%',
        justifyContent: 'space-between',
      }}
    >
      <Field
        component={Dropdown}
        label="View"
        labelSx={graphLabelSx}
        name={`${name}.viewId`}
        options={data}
        wrapperSx={graphWrapperSx}
      />
      <GridButton
        icon="trash"
        index={index}
        label="Remove"
        onClick={() => fields.remove(index)}
        sx={{
          // border: '1px solid',
          // borderRadius: 1,
          color: 'error',
        }}
      />
      {appendTools}
      <TooltipBox id={`gridToolbar-${index}`} />
    </Box>
  );
};

GridButton.propTypes = {
  icon: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  sx: PropTypes.shape({}),
};

GridButton.defaultProps = {
  sx: {},
};

GraphToolbar.propTypes = {
  appendTools: PropTypes.node,
  data: PropTypes.shape({}).isRequired,
  fields: PropTypes.shape({
    remove: PropTypes.func,
  }).isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

GraphToolbar.defaultProps = {
  appendTools: null,
};

export default GraphToolbar;
