import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { AuthContext } from '@shoreag/auth';
import Box from '@shoreag/base/Box';
import Modal from '@shoreag/base/Modal';
import { capitalCase } from 'change-case';
import Spinner from '@shoreag/base/Spinner';
import stripTypename from '@shoreag/helpers/strip-typename';
import { ThemeContext } from 'styled-components';
import { useMutation, useQuery } from 'react-apollo';
import { LOCAL_STORAGE_ACTIONS } from '../../utilities/constants';
import Route from '../Route';
import NotFoundPage from '../../routes/default/404';
import ButtonGroups from '../ButtonGroups';
import ToolbarButton from '../ToolbarButton';
import ChartCardContainer from '../AnalyticsCharts/ChartCardContainer';
import AddChartFormModal from '../AnalyticsCharts/AddChartFormModal';
import dashboardConstants from '../../utilities/dashboard-constants';
import AddChartPlaceHolder from '../AnalyticsCharts/AddChartPlaceHolder';
import AnalyticsFilterForm from '../AllRecordsDashboardPage/AnalyticsFilterForm';
import { CHARTS, CHART_TYPES } from '../AnalyticsCharts/chart-config';
import createAnalyticsChartMutation from '../../graphql/mutations/create-analytics-chart.gql';
import updateAnalyticsChartMutation from '../../graphql/mutations/update-analytics-chart.gql';
import analyticsChartQuery from '../../graphql/queries/analytics-chart.gql';
import {
  ScatterToBarChart,
  SunBrustChart,
  StackedHorizontalBarChart,
  StackedHorizontalBarWithNegativeChart,
  StackedLinesChart,
} from '../AnalyticsCharts/Charts';
import PillsGroup from '../PillsGroup';

const AnalyticsDashboardPage = ({ location }) => {
  const theme = useContext(ThemeContext);
  const { isAuthenticated, user } = useContext(AuthContext);
  const [createAnalyticsChart] = useMutation(createAnalyticsChartMutation);
  const [updateAnalyticsChart] = useMutation(updateAnalyticsChartMutation);

  const cognitoUsername = get(user, 'cognitoUsername', '');
  if (isAuthenticated && !user.isAdmin) return <NotFoundPage />;

  const [showAddChartModal, setShowAddChartModal] = useState(false);
  const [dashboardCharts, setDashboardCharts] = useState([]);
  const [selectedToRemove, setSelectedToRemove] = useState({});
  const [selectedToEdit, setSelectedToEdit] = useState({});
  const [darkMode] = useState(false);

  const { filters } = dashboardConstants.getCriterias({
    search: location.search,
    type: 'analytics_charts',
  });

  const { values } = filters;

  const { data, loading: analyticsChartLoading } = useQuery(
    analyticsChartQuery,
    {
      fetchPolicy: 'network-only',
      skip: !cognitoUsername,
      variables: { id: cognitoUsername },
    }
  );

  useEffect(() => {
    if (data) {
      const analyticsChart = get(data, 'analyticsChart.chartData', []);
      setDashboardCharts(stripTypename(analyticsChart));
    }
  }, [data]);

  const handleUpdateDashboard = async ({ action, chartId, value }) => {
    let updatedDashboardCharts = [...dashboardCharts];
    if (action === LOCAL_STORAGE_ACTIONS.REMOVE) {
      updatedDashboardCharts = updatedDashboardCharts.filter(
        (i) => i.chartId !== chartId
      );
      setSelectedToRemove((prev) => ({ ...prev, removeInProgress: true }));
    }
    if (action === LOCAL_STORAGE_ACTIONS.EDIT) {
      updatedDashboardCharts = [...dashboardCharts].map((i) => {
        return chartId === i.chartId ? value : i;
      });
    }
    if (action === LOCAL_STORAGE_ACTIONS.ADD) {
      updatedDashboardCharts.push(value);
    }
    const payload = {
      variables: { chartData: updatedDashboardCharts, id: cognitoUsername },
    };
    const analyticsId = get(data, 'analyticsChart.id', '');
    if (analyticsId) {
      const {
        data: {
          updateAnalyticsChart: { id },
        },
      } = await updateAnalyticsChart(payload);
      if (id) {
        setDashboardCharts(updatedDashboardCharts);
      }
    } else {
      const {
        data: {
          createAnalyticsChart: { id },
        },
      } = await createAnalyticsChart(payload);

      if (id) {
        setDashboardCharts(updatedDashboardCharts);
      }
    }
    setSelectedToRemove({});
  };

  const CHART_MAP = {
    [CHART_TYPES.scatterToBar]: ScatterToBarChart,
    [CHART_TYPES.stackedHorizontalBar]: StackedHorizontalBarChart,
    [CHART_TYPES.stackedHorizontalBarWithNegative]: StackedHorizontalBarWithNegativeChart,
    [CHART_TYPES.stackedLine]: StackedLinesChart,
    [CHART_TYPES.sunBrust]: SunBrustChart,
  };

  const removeInProgress = get(selectedToRemove, 'removeInProgress', false);
  const showAddEditChartForm = showAddChartModal || !isEmpty(selectedToEdit);

  return (
    <Route
      header={{
        rightContainer: (
          <ButtonGroups>
            <AnalyticsFilterForm
              filters={filters}
              type={filters.created.type}
              values={values}
            />
            <ToolbarButton
              icon="bar-chart"
              label="Add Chart"
              onClick={() => setShowAddChartModal(!showAddChartModal)}
            />
          </ButtonGroups>
        ),
        title: 'Analytics',
      }}
      isPrivate
    >
      {analyticsChartLoading && <Spinner />}
      {!analyticsChartLoading && (
        <ChartCardContainer
          placeHolder={
            <AddChartPlaceHolder
              onAddNewChart={() => setShowAddChartModal(!showAddChartModal)}
            />
          }
        >
          {dashboardCharts.map((chart) => {
            const { chartName, chartId } = chart;
            const { chartType } = CHARTS[chartName];
            return (
              <Box
                key={`${chartName}-${chartId}`}
                as={CHART_MAP[chartType]}
                chartData={chart}
                darkMode={darkMode}
                location={location}
                onEdit={() => setSelectedToEdit(chart)}
                onRemove={() => setSelectedToRemove(chart)}
              />
            );
          })}
        </ChartCardContainer>
      )}
      {showAddEditChartForm && (
        <AddChartFormModal
          initialValues={selectedToEdit}
          isOpen={showAddEditChartForm}
          onAddChart={(value) => {
            handleUpdateDashboard({
              action: LOCAL_STORAGE_ACTIONS.ADD,
              value,
            });
          }}
          onClose={() => {
            setSelectedToEdit({});
            setShowAddChartModal(false);
          }}
          onEditChart={(value) => {
            handleUpdateDashboard({
              action: LOCAL_STORAGE_ACTIONS.EDIT,
              chartId: value.chartId,
              value,
            });
          }}
        />
      )}
      {!isEmpty(selectedToRemove) && (
        <Modal
          closeText="Cancel"
          isOpen={!!selectedToRemove}
          onClose={() => {
            setSelectedToRemove(null);
          }}
          onSubmit={() => {
            const { chartId } = selectedToRemove;
            handleUpdateDashboard({
              action: LOCAL_STORAGE_ACTIONS.REMOVE,
              chartId,
            });
          }}
          style={{
            content: {
              maxWidth: theme.sizes.maxWidths.form,
            },
          }}
          submitText="Remove"
          submitting={removeInProgress}
          title="Remove Chart"
        >
          <h3>{capitalCase(CHARTS[selectedToRemove.chartName]?.label)}</h3>
          Partner:
          <PillsGroup
            data={selectedToRemove.partner.map((p) => ({
              id: p.id,
              label: p.partnerName,
            }))}
            pillSx={{
              '&:hover': { bg: 'accentExtraLight', color: 'accent' },
              bg: 'accentExtraLight',
              color: 'accent',
            }}
            viewOnly
            wrapperSx={{ mt: 4 }}
          />
          <br />
          <br />
          Are sure you want to remove it from dashboard?
        </Modal>
      )}
    </Route>
  );
};

AnalyticsDashboardPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

export default AnalyticsDashboardPage;
