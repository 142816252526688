import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import { get, isEmpty, noop, toUpper } from 'lodash';
import { Field, Form } from 'react-final-form';
import { required } from '@shoreag/validations';
import { AuthContext } from '@shoreag/auth';
import { capitalCase, sentenceCase } from 'change-case';
import Box from '@shoreag/base/Box';
import Button from '@shoreag/base/Button';
import Dropdown from '@shoreag/base/Dropdown';
import DrawerSidebar from '../../DrawerSidebar';
import MultiSelectDropdown from '../../MultiSelectDropdown';
import partnerQuery from '../../../graphql/queries/all-partner.gql';
import tradingPartners from '../../../utilities/get-trading-partners';
import { CHARTS } from '../chart-config';
import PillsGroup from '../../PillsGroup';

const AddChartFormModal = ({
  isOpen,
  onClose,
  onAddChart,
  onEditChart,
  initialValues,
}) => {
  const { user } = useContext(AuthContext);
  const { data, loading } = useQuery(partnerQuery);
  const partnersList = tradingPartners(get(data, 'allPartner', []), user);
  const [selectedPartnersList, setSelectedPartnersList] = useState([]);
  const [initialFormValues, setInitialFormValues] = useState({});

  const handleFormValues = (formContext, chartName) => {
    const chartCriteria = CHARTS[chartName];
    const multiplePartner = get(chartCriteria, 'multiplePartner', false);

    if (!multiplePartner && selectedPartnersList.length > 1) {
      formContext.form.change(`partner`, '');
      setSelectedPartnersList([]);
    }
  };

  const handleModalClose = (formContext) => {
    formContext.form.reset();
    setSelectedPartnersList([]);
    onClose();
  };

  useEffect(() => {
    if (!isEmpty(initialValues)) {
      const initialPartnerValues = get(initialValues, 'partner', []).map(
        (p) => p.id
      );
      setSelectedPartnersList(initialPartnerValues);
      setInitialFormValues({
        ...initialValues,
        partner: initialPartnerValues,
      });
    }
  }, [initialValues]);

  return (
    <Form
      initialValues={{ ...initialFormValues }}
      onSubmit={noop}
      render={(formContext) => {
        const { values } = formContext;
        const isChartId = get(values, 'chartId', '');
        const chartName = get(values, 'chartName', '');
        const partner = get(values, 'partner', []);
        const selectedPartner =
          partner && typeof partner === 'string' ? [partner] : partner;
        const chartCriteria = CHARTS[chartName];
        const multiplePartner = get(chartCriteria, 'multiplePartner', false);
        const label = get(chartCriteria, 'label', '');
        const modalTitle = isChartId ? 'Edit' : 'Select';
        const modalSubtitle =
          isChartId && label ? capitalCase(label) : 'Chart and partner(s)';
        return (
          <DrawerSidebar
            contentSx={{
              maxWidth: '40%',
            }}
            footer={
              <Box
                sx={{
                  display: 'flex',
                  gap: '1rem',
                  justifyContent: 'center',
                  p: 5,
                }}
              >
                <Button
                  onClick={() => handleModalClose(formContext)}
                  variant="buttons.cancel"
                >
                  Cancel
                </Button>
                {isChartId ? (
                  <Button
                    disabled={formContext.invalid}
                    onClick={() => {
                      const partner = partnersList
                        .filter((p) => selectedPartner.includes(p.id))
                        .filter((i) => i)
                        .map((p) => ({ id: p.id, partnerName: p.partnerName }));
                      onEditChart({
                        chartId: isChartId,
                        ...values,
                        partner,
                      });
                      handleModalClose(formContext);
                    }}
                  >
                    Edit
                  </Button>
                ) : (
                  <Button
                    disabled={formContext.invalid}
                    onClick={() => {
                      const partner = partnersList
                        .filter((p) => selectedPartner.includes(p.id))
                        .filter((i) => i)
                        .map((p) => ({ id: p.id, partnerName: p.partnerName }));
                      onAddChart({
                        chartId: `spr:bz:chart::${Date.now()}`,
                        ...values,
                        partner,
                      });
                      handleModalClose(formContext);
                    }}
                  >
                    Add
                  </Button>
                )}
              </Box>
            }
            isOpen={isOpen}
            onClose={() => onClose()}
            subtitle={modalSubtitle}
            title={modalTitle}
          >
            <Field
              component={Dropdown}
              data-cy="chartName"
              disabled={isChartId}
              isClearable
              label="Select chart"
              name="chartName"
              onChange={(chartName) => {
                handleFormValues(formContext, chartName);
              }}
              options={Object.keys(CHARTS)
                .map((key) => {
                  const ifDisabled = CHARTS[key].disabled;
                  return ifDisabled
                    ? null
                    : {
                        label: capitalCase(CHARTS[key].label),
                        subtext: toUpper(sentenceCase(CHARTS[key].chartType)),
                        value: key,
                      };
                })
                .filter((i) => i)}
              validate={required}
            />
            <Field
              component={MultiSelectDropdown}
              disabled={!chartName}
              isClearable
              isLoading={loading}
              isMulti={multiplePartner}
              label={multiplePartner ? 'Select partners' : 'Select partner'}
              name="partner"
              onChange={(val) => {
                setSelectedPartnersList(
                  val && typeof val === 'string' ? [val] : val
                );
              }}
              options={partnersList.map((partner) => ({
                label: partner.partnerName,
                value: partner.id,
              }))}
              validate={required}
            />
            {selectedPartnersList && (
              <PillsGroup
                data={selectedPartnersList
                  .map((i) => partnersList.find((p) => i === p.id))
                  .map((p) => ({ id: p.id, label: p.partnerName }))}
                onRemove={(removedPartner) => {
                  const updatedPartners = selectedPartner.filter(
                    (pId) => pId !== removedPartner.id
                  );
                  formContext.form.change(`partner`, updatedPartners);
                  setSelectedPartnersList(updatedPartners);
                }}
                wrapperSx={{ mt: 4 }}
              />
            )}
          </DrawerSidebar>
        );
      }}
    />
  );
};

AddChartFormModal.propTypes = {
  initialValues: PropTypes.shape({}),
  isOpen: PropTypes.bool,
  onAddChart: PropTypes.func,
  onClose: PropTypes.func,
  onEditChart: PropTypes.func,
};

AddChartFormModal.defaultProps = {
  initialValues: {},
  isOpen: false,
  onAddChart: () => null,
  onClose: () => null,
  onEditChart: () => null,
};

export default AddChartFormModal;
