import PropTypes from 'prop-types';
import React from 'react';
import Spinner from '@shoreag/base/Spinner';
import { useQuery } from 'react-apollo';
import NotFoundPage from '../../routes/default/404';
import Route from '../Route';
import datasetQuery from '../../graphql/queries/dataset.gql';
import ButtonGroups from '../ButtonGroups';
import ToolbarButton from '../ToolbarButton';
import RecordDetails from '../RecordDetails';

const AnalyticsDashboardDetailsPage = ({ datasetId }) => {
  const { data, loading } = useQuery(datasetQuery, {
    variables: { id: datasetId },
  });
  const hasStandardizedData = false;
  const haveZipFiles = false;

  return (
    <Route
      header={{
        icon: 'documents',
        rightContainer: (
          <ButtonGroups>
            {!haveZipFiles && hasStandardizedData && (
              <ToolbarButton
                icon="database"
                label="View Files"
                link={`/datasets/${datasetId}/data`}
              />
            )}
          </ButtonGroups>
        ),
        title: 'Details',
        type: 'Record',
      }}
      isPrivate
    >
      {loading && <Spinner />}
      {!loading && !data && <NotFoundPage />}
      {!!data && (
        <>
          <RecordDetails
            dataset={data.dataset}
            id={data.dataset.id}
            type="datasetId"
          />
        </>
      )}
    </Route>
  );
};

AnalyticsDashboardDetailsPage.propTypes = {
  datasetId: PropTypes.string,
};

AnalyticsDashboardDetailsPage.defaultProps = {
  datasetId: null,
};

export default AnalyticsDashboardDetailsPage;
