const setDashboardChartAxisLabel = () => ({
  fontSize: 10,
  formatter: (value) => {
    if (/^[A-Z][a-z]+(?:[A-Z][a-z]+)*$/.test(value)) {
      const formattedPascalCase = value.replace(/([A-Z])/g, ' $1').trim();
      return formattedPascalCase.replace(/\s/g, '\n');
    }

    if (value && typeof value === 'string') {
      return value.replace(/\s/g, '\n');
    }
    return value;
  },
});

export default setDashboardChartAxisLabel;
