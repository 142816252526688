import Box from '@shoreag/base/Box';
import Button from '@shoreag/base/Button';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

const ToolbarMiniButton = ({
  active,
  icon,
  indicator,
  label,
  link,
  onClick,
}) => {
  const sx = {
    '&:hover': {
      bg: 'accentDark',
      borderColor: 'accentDark',
      color: 'white',
      fill: 'white',
    },
    bg: active ? 'accentLight' : 'white',
    borderColor: 'accentLight',
    borderRadius: 1,
    borderStyle: 'solid',
    borderWidth: 1,
    color: active ? 'white' : 'accentLight',
    fill: active ? 'white' : 'accentLight',
    p: 2,
    pl: 4,
    pr: 4,
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        fontSize: 1,
        position: 'relative',
        textAlign: 'center',
      }}
      title={label}
    >
      <Button disabled={active} link={link} onClick={onClick} sx={sx}>
        <Icon
          svg={icon}
          sx={{
            width: '1.25rem',
          }}
        />
      </Button>
      <Box
        sx={{
          color: active ? 'accentLight' : 'grays.4',
          fontSize: 2,
        }}
      >
        {label}
      </Box>
      {indicator ? (
        <Box
          sx={{
            bg: 'accentDark',
            borderColor: 'accentDark',
            borderRadius: 2,
            borderStyle: 'solid',
            borderWidth: 1,
            color: 'white',
            fontSize: 1,
            minWidth: '1.5rem',
            p: 1,
            pb: 0,
            position: 'absolute',
            pt: 0,
            right: -2,
            top: -2,
            zIndex: 999,
          }}
        >
          {indicator}
        </Box>
      ) : null}
    </Box>
  );
};

ToolbarMiniButton.defaultProps = {
  active: false,
  icon: '',
  indicator: 0,
  label: '',
  link: '',
  onClick: () => {},
};

ToolbarMiniButton.propTypes = {
  active: PropTypes.bool,
  icon: PropTypes.string,
  indicator: PropTypes.number,
  label: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.func,
};

export default ToolbarMiniButton;
