import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useQuery } from 'react-apollo';
import { AuthContext } from '@shoreag/auth';
import { parse, stringify } from 'query-string';
import { sentenceCase } from 'change-case';
import ECharts from '../ECharts';
import ChartCard from '../../ChartCard';
import {
  CARD_TYPES,
  CHARTS,
  DARKMODE_DEFAULT_OPTIONS,
} from '../../chart-config';
import dashboardConstants from '../../../../utilities/dashboard-constants';
import partnerQuery from '../../../../graphql/queries/all-partner.gql';
import errorCountAndSuccessCountByPartnerQuery from '../../../../graphql/queries/error-count-and-success-count-by-partner-analytics.gql';
import tradingPartnersFileCountQuery from '../../../../graphql/queries/trading-partners-file-count-analytics.gql';
import stepLengthVsTotalProcessingTimeQuery from '../../../../graphql/queries/step-length-vs-total-processing-time-analytics.gql';
import setDashboardChartAxisLabel from '../../../../utilities/set-dashboard-chart-axis-label';

const StackedHorizontalBarChart = ({
  chartData,
  onRemove,
  onEdit,
  darkMode,
  location,
}) => {
  const { user } = useContext(AuthContext);

  const { chartName, partner } = chartData;
  const { label, esQuery, getColorSeries, allowEdit } = CHARTS[chartName];
  const { ratio } = get(CHARTS[chartName], 'sizeRatio', CARD_TYPES.landscape);
  const { term, aggs } = esQuery;

  const { data: allPartnersData, loading: allPartnersLoading } = useQuery(
    partnerQuery
  );

  const setXAxisLabelValue = (value) =>
    value.map((i) => ({ label: sentenceCase(i), value: i }));

  const CHART_QUERY_CONSTANT = {
    errorCountAndSuccessCountByPartner: {
      atrribute: 'errorCountAndSuccessCountByPartnerAnalytics',
      query: errorCountAndSuccessCountByPartnerQuery,
      xAxis: () => [
        { label: 'In progress', value: 'In progress' },
        { label: 'Completed', value: 'Completed' },
        { label: 'Failed', value: 'Failed' },
      ],
    },
    stepLengthVsTotalProcessingTime: {
      atrribute: 'stepLengthVsTotalProcessingTimeAnalytics',
      query: stepLengthVsTotalProcessingTimeQuery,
      xAxis: setXAxisLabelValue,
      xAxisName: 'Processing\nTime',
    },
    tradingPartnersFileCount: {
      atrribute: 'tradingPartnersFileCountAnalytics',
      query: tradingPartnersFileCountQuery,
      xAxis: setXAxisLabelValue,
    },
  };

  const { xAxis, xAxisName } = CHART_QUERY_CONSTANT[chartName];

  const searchFilters = stringify({
    filters: stringify({
      ...parse(get(parse(location.search), 'filters', {})),
      partner: partner.map((p) => p.id),
    }),
  });

  const { filters } = dashboardConstants.getCriterias({
    search: searchFilters,
    type: 'analytics_charts',
  });

  const analyticsSearchBody = dashboardConstants.getQuery({
    filters,
    user,
  });

  const { data, loading, error } = useQuery(
    CHART_QUERY_CONSTANT[chartName].query,
    {
      fetchPolicy: 'network-only',
      pollInterval: 5000,
      variables: {
        field: term,
        fields: aggs,
        searchBody: JSON.stringify(analyticsSearchBody),
      },
    }
  );
  const { data: analyticsData } = get(
    data,
    CHART_QUERY_CONSTANT[chartName].atrribute,
    []
  );
  const formatTime = (value) => {
    const numValue = parseFloat(value);
    if (Number.isNaN(Number(numValue))) {
      return '-';
    }
    if (numValue < 60) {
      return `${numValue.toFixed(2)}s`;
    }
    if (numValue < 3600) {
      return `${(numValue / 60).toFixed(2)}min`;
    }
    return `${(numValue / 3600).toFixed(2)}hr`;
  };
  let customizedOptions = {};
  const notData = !loading && analyticsData?.length === 0;
  if (!loading && !allPartnersLoading && !error) {
    let xAxisLabels = {};
    analyticsData.map((i) =>
      i.value.forEach((l) => {
        xAxisLabels = { ...xAxisLabels, [l.label]: '' };
      })
    );
    const options = {
      series: xAxis(Object.keys(xAxisLabels)).map((i) => ({
        data: analyticsData.map((p) => {
          let val = get(
            p.value.find((status) => status.label === i.value),
            'value',
            ''
          );
          if (chartName === 'stepLengthVsTotalProcessingTime' && val) {
            val = parseFloat(val); // Keep as numeric value for the chart
          }
          return val ? Math.floor(val) : '';
        }),
        name: i.label,
      })),
      yAxis: {
        data: analyticsData.map((p) =>
          get(
            get(allPartnersData, 'allPartner', []).find(
              (ap) => ap.id === p.label
            ),
            'partnerName',
            p.label
          )
        ),
      },
    };

    const updatedOptions = {
      color: getColorSeries(),
      legend: {},
      tooltip: {
        axisPointer: {
          type: 'shadow',
        },
        formatter: (params) => {
          const formattedValues = params.map((item) => {
            if (chartName === 'stepLengthVsTotalProcessingTime') {
              const formattedTime = formatTime(item.value);
              return `${item.marker} ${item.seriesName}: ${formattedTime}`;
            }
            return `${item.marker} ${item.seriesName}: ${item.value}`;
          });
          return formattedValues.join('<br/>');
        },
        trigger: 'axis',
      },
      xAxis: {
        axisTick: {
          show: true,
        },
        name: xAxisName,
        nameTextStyle: { fontSize: '10px' },
        type: 'value',
      },
      ...(darkMode ? DARKMODE_DEFAULT_OPTIONS : {}),
      ...options,
    };
    customizedOptions = {
      ...updatedOptions,
      series: options.series.map((i) => ({
        ...i,
        emphasis: {
          focus: 'series',
        },
        label: {
          formatter: (params) => {
            if (chartName === 'stepLengthVsTotalProcessingTime') {
              return formatTime(params.value);
            }
            return params.value;
          },
          show: false,
        },
        stack: 'total',
        type: 'bar',
      })),
      yAxis: {
        axisLabel: setDashboardChartAxisLabel(),
        axisLine: { show: false },
        axisTick: {
          show: true,
        },
        data: options.yAxis.data,
        type: 'category',
      },
    };
  }

  let content = <ECharts option={customizedOptions} />;
  if (notData) {
    content = 'No data found!';
  }
  if (error) {
    content = 'Something went wrong!';
  }

  return (
    <ChartCard
      allowEdit={allowEdit}
      bodySx={{ aspectRatio: ratio }}
      darkMode={darkMode}
      label={label}
      loading={loading || allPartnersLoading}
      onEdit={onEdit}
      onRemove={onRemove}
    >
      {content}
    </ChartCard>
  );
};

StackedHorizontalBarChart.propTypes = {
  chartData: PropTypes.shape({
    chartName: PropTypes.string,
    partner: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  darkMode: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
};

StackedHorizontalBarChart.defaultProps = {
  darkMode: false,
  onEdit: () => null,
  onRemove: () => null,
};

export default StackedHorizontalBarChart;
