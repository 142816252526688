import Box from '@shoreag/base/Box';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

const PillsGroup = ({ wrapperSx, pillSx, data, onRemove, viewOnly }) => {
  return (
    <Box
      sx={{ display: 'flex', flexWrap: 'wrap', mb: -2, mr: -2, ...wrapperSx }}
    >
      {data.map((item, index) => {
        const { label } = item;
        return (
          <Box
            key={`${label}-${index}`}
            sx={{
              alignItems: 'center',
              bg: 'accentDark',
              borderRadius: 1,
              color: 'white',
              display: 'flex',
              fontWeight: 'normal',
              height: '1.4rem',
              justifyContent: 'center',
              mb: 2,
              minWidth: '1.4rem',
              mr: 2,
              pl: 4,
              pr: viewOnly ? 4 : 2,
              py: 4,
              textTransform: 'none',
              ...pillSx,
            }}
            variant="buttons.primary"
          >
            <Box
              sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
              title={label}
            >
              {label}
            </Box>
            {!viewOnly ? (
              <Icon
                onClick={() => onRemove(item)}
                svg="minus"
                sx={{
                  bg: 'white',
                  borderRadius: '50%',
                  color: 'accentDark',
                  cursor: 'pointer',
                  display: 'inline-flex',
                  height: '1rem',
                  ml: 3,
                  px: '.25rem',
                  width: '1rem',
                }}
              />
            ) : null}
          </Box>
        );
      })}
    </Box>
  );
};

PillsGroup.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    })
  ).isRequired,
  onRemove: PropTypes.func,
  pillSx: PropTypes.shape({}),
  viewOnly: PropTypes.bool,
  wrapperSx: PropTypes.shape({}),
};

PillsGroup.defaultProps = {
  onRemove: () => null,
  pillSx: {},
  viewOnly: false,
  wrapperSx: {},
};

export default PillsGroup;
