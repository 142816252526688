import Modal from '@shoreag/base/Modal';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import Box from '@shoreag/base/Box';
import Icon from '../Icon';
import ButtonGroups from '../ButtonGroups';

const DrawerSidebar = ({
  allowMaximizeView,
  title,
  subtitle,
  children,
  onClose,
  contentSx,
  overlaySx,
  ...rest
}) => {
  const theme = useContext(ThemeContext);
  const [maximizeView, setMaximizeView] = useState(false);
  const minWidth = '450px';
  return (
    <Modal
      bodySx={{ flex: 'auto', overflow: 'auto' }}
      header={
        <Box
          sx={{
            alignItems: 'center',
            borderBottomStyle: 'solid',
            borderColor: 'grays.2',
            borderWidth: '1px',
            display: 'flex',
            flex: 'none',
            height: theme.sizes.heights.header,
            justifyContent: 'space-between',
            px: [5, null, null, 6],
          }}
        >
          {(title || subtitle) && (
            <Box>
              {title && (
                <Box
                  sx={{
                    color: 'accentLight',
                    fontSize: 2,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  {title}
                </Box>
              )}
              {subtitle && (
                <Box
                  as="h1"
                  sx={{
                    display: 'box',
                    fontSize: 5,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {subtitle}
                </Box>
              )}
            </Box>
          )}
          <ButtonGroups>
            {allowMaximizeView && (
              <Icon
                onClick={() => setMaximizeView(!maximizeView)}
                svg={maximizeView ? 'minimize' : 'maximize'}
                sx={{
                  color: maximizeView ? 'accent' : 'textSubtle',
                  cursor: 'pointer',
                  height: '30px',
                  ml: 'auto',
                  p: 1,
                  width: '30px',
                }}
                title={maximizeView ? 'Minimize' : 'Maximize'}
              />
            )}
            <Icon
              onClick={onClose}
              svg="close"
              sx={{
                color: 'textSubtle',
                cursor: 'pointer',
                height: '30px',
                ml: 'auto',
                p: 3,
                width: '30px',
              }}
            />
          </ButtonGroups>
        </Box>
      }
      style={{
        content: {
          borderRadius: 0,
          boxShadow: theme.shadows[2],
          display: 'flex',
          flexDirection: 'column',
          height: `calc(100% - ${theme.sizes.heights.header})`,
          left: 'auto',
          mb: 6,
          overflow: rest.isOpen ? 'visible' : 'hidden',
          pointerEvents: 'all',
          position: 'fixed',
          right: '0',
          top: theme.sizes.heights.header,
          transform: rest.isOpen ? 'translateX(0%)' : 'translateX(100%)',
          width: '100%',
          ...contentSx,
          ...{
            maxWidth: maximizeView ? '100%' : contentSx.maxWidth || minWidth,
          },
        },
        overlay: {
          backgroundColor: 'transparent',
          pointerEvents: 'none',
          ...overlaySx,
        },
      }}
      {...rest}
    >
      {children}
    </Modal>
  );
};

DrawerSidebar.propTypes = {
  allowMaximizeView: PropTypes.bool,
  children: PropTypes.node.isRequired,
  contentSx: PropTypes.shape({
    maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onClose: PropTypes.func,
  overlaySx: PropTypes.shape({}),
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

DrawerSidebar.defaultProps = {
  allowMaximizeView: false,
  contentSx: {},
  onClose: () => null,
  overlaySx: {},
  subtitle: '',
  title: '',
};

export default DrawerSidebar;
