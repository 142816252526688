import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useQuery } from 'react-apollo';
import { AuthContext } from '@shoreag/auth';
import { parse, stringify } from 'query-string';
import ECharts from '../ECharts';
import ChartCard from '../../ChartCard';
import {
  CARD_TYPES,
  CHARTS,
  DARKMODE_DEFAULT_OPTIONS,
} from '../../chart-config';
import dashboardConstants from '../../../../utilities/dashboard-constants';
import partnerQuery from '../../../../graphql/queries/all-partner.gql';
import recordsTransformedQuery from '../../../../graphql/queries/records-transformed-analytics.gql';
import setDashboardChartAxisLabel from '../../../../utilities/set-dashboard-chart-axis-label';

const StackedHorizontalBarWithNegativeChart = ({
  chartData,
  onRemove,
  onEdit,
  darkMode,
  location,
}) => {
  const { user } = useContext(AuthContext);

  const { chartName, partner } = chartData;
  const { label, esQuery, getColorSeries, allowEdit } = CHARTS[chartName];
  const { ratio } = get(CHARTS[chartName], 'sizeRatio', CARD_TYPES.landscape);
  const { term, aggs } = esQuery;

  const { data: allPartnersData, loading: allPartnersLoading } = useQuery(
    partnerQuery
  );

  const CHART_QUERY_CONSTANT = {
    recordsTransformed: {
      atrribute: 'recordsTransformedAnalytics',
      query: recordsTransformedQuery,
    },
  };

  const searchFilters = stringify({
    filters: stringify({
      ...parse(get(parse(location.search), 'filters', {})),
      partner: partner.map((p) => p.id),
    }),
  });

  const { filters } = dashboardConstants.getCriterias({
    search: searchFilters,
    type: 'analytics_charts',
  });

  const analyticsSearchBody = dashboardConstants.getQuery({
    filters,
    user,
  });

  const { data, loading } = useQuery(CHART_QUERY_CONSTANT[chartName].query, {
    fetchPolicy: 'network-only',
    pollInterval: 5000,
    variables: {
      field: term,
      fields: aggs,
      searchBody: JSON.stringify(analyticsSearchBody),
    },
  });
  const { data: analyticsData } = get(
    data,
    CHART_QUERY_CONSTANT[chartName].atrribute,
    []
  );
  let customizedOptions = {};
  const notData = !loading && analyticsData?.length === 0;
  if (!loading && !allPartnersLoading) {
    const partnerWiseData = analyticsData.map((i) => {
      let dataObj = {};
      i.value.forEach((v) => {
        dataObj = { ...dataObj, [v.label]: v.value };
      });
      return {
        data: dataObj,
        id: i.label,
        partnerName: get(
          get(allPartnersData, 'allPartner', []).find((p) => p.id === i.label),
          'partnerName',
          i.label
        ),
      };
    });

    const getPosNeg = (v, x) => (!v || v === 0 ? '' : v * x);

    const options = {
      legend: {
        data: aggs,
      },
      series: aggs.map((i) => {
        const isGoodOrder = i === aggs[0];
        return {
          data: partnerWiseData.map((j) => {
            const value = get(j.data, i, '');
            return isGoodOrder ? getPosNeg(value, 1) : getPosNeg(value, -1);
          }),
          name: isGoodOrder ? 'In Good Order' : 'Not in Good Order',
        };
      }),
      yAxis: {
        data: partnerWiseData.map((i) => i.partnerName),
      },
    };

    const updatedOptions = {
      color: getColorSeries(),
      tooltip: {
        axisPointer: {
          type: 'shadow',
        },
        trigger: 'axis',
      },
      xAxis: {
        axisTick: {
          show: true,
        },
        type: 'value',
      },
      ...(darkMode ? DARKMODE_DEFAULT_OPTIONS : {}),
      ...options,
    };
    customizedOptions = {
      ...updatedOptions,
      series: options.series.map((i) => ({
        ...i,
        emphasis: {
          focus: 'series',
        },
        label: {
          position: 'inside',
          show: true,
        },
        stack: 'total',
        type: 'bar',
      })),
      yAxis: {
        axisLabel: setDashboardChartAxisLabel(),
        axisTick: {
          show: false,
        },
        data: options.yAxis.data,
        type: 'category',
      },
    };
  }

  let content = <ECharts option={customizedOptions} />;
  if (notData) {
    content = 'No data found!';
  }

  return (
    <ChartCard
      allowEdit={allowEdit}
      bodySx={{ aspectRatio: ratio }}
      darkMode={darkMode}
      label={label}
      loading={loading || allPartnersLoading}
      onEdit={onEdit}
      onRemove={onRemove}
    >
      {content}
    </ChartCard>
  );
};

StackedHorizontalBarWithNegativeChart.propTypes = {
  chartData: PropTypes.shape({
    chartName: PropTypes.string,
    partner: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  darkMode: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
};

StackedHorizontalBarWithNegativeChart.defaultProps = {
  darkMode: false,
  onEdit: () => null,
  onRemove: () => null,
};

export default StackedHorizontalBarWithNegativeChart;
