import AutoSave from '@shoreag/base/AutoSave';
import PropTypes from 'prop-types';
import React from 'react';
import noop from 'lodash/noop';
import { Field, Form } from 'react-final-form';
import { Location } from '@reach/router';
import DatePicker from '@shoreag/base/DatePicker';
import { navigate } from 'gatsby';
import setDashboardURL from '../../../utilities/set-dashboard-url';

const AnalyticsFilterForm = ({ filters, values, type }) => {
  let result;
  switch (type) {
    case 'DatePicker': {
      result = (
        <Field
          component={DatePicker}
          dateDisplayFormat="M/d/yyyy"
          labelSx={{
            fontSize: 1,
          }}
          name="created"
        />
      );
      break;
    }
    default: {
      result = null;
    }
  }
  return (
    <Location>
      {({ location: { pathname } }) => (
        <Form
          initialValues={values}
          onSubmit={noop}
          render={() => {
            return (
              <>
                <AutoSave
                  save={(all, changed, added, deleted, values) => {
                    return navigate(
                      setDashboardURL({
                        filters,
                        filterValues: values,
                        pathname,
                      }),
                      { replace: true }
                    );
                  }}
                />
                {result}
              </>
            );
          }}
        />
      )}
    </Location>
  );
};

AnalyticsFilterForm.propTypes = {
  filters: PropTypes.shape({}).isRequired,
  type: PropTypes.string,
  values: PropTypes.shape({}),
};

AnalyticsFilterForm.defaultProps = {
  type: '',
  values: {},
};

export default AnalyticsFilterForm;
