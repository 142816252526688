import renderDropdownMenuItem from './render-dropdown-menu-item';

const setActionDropdown = (items) => {
  const dropdownActions = [];
  const dropdownItems = [];
  items.forEach((i) => {
    if (i.display) {
      dropdownActions.push(i.action);
      dropdownItems.push(renderDropdownMenuItem(i.label));
    }
  });
  return {
    dropdownActions,
    dropdownItems,
  };
};

export default setActionDropdown;
