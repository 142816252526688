import React, { useContext, useState } from 'react';
import Box from '@shoreag/base/Box';
import { AuthContext } from '@shoreag/auth';
import { get, noop } from 'lodash';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import Dropdown from '@shoreag/base/Dropdown';
import { useQuery } from 'react-apollo';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { bisector } from 'd3-array';
import Button from '@shoreag/base/Button';
import moment from 'moment';
import formatDuration from '@shoreag/helpers/format-duration';
import Route from '../Route';
import GraphToolbar from '../Graph/GraphToolbar';
import ToolbarButton from '../ToolbarButton';
import Icon from '../Icon';
import allGraphsQuery from '../../graphql/queries/all-graphs.gql';
import allViewsQuery from '../../graphql/queries/all-views.gql';
import PieChart from '../PieChart';
// import formatFileSize from '../../utilities/format-file-size';
import AreaGraph from '../AreaGraph';
import LoginIllustration from '../../images/illustrations/login.svg';
// import tradingPartners from '../../utilities/get-trading-partners';
// import allPartnerQuery from '../../graphql/queries/all-partner.gql';
import ButtonGroups from '../ButtonGroups';
import localStorage from '../../utilities/local-storage';
// import isPermitted from '../../utilities/is-permitted';
// import {
//   PERMISSION_ACTIONS,
//   PERMISSION_RESOURCES,
// } from '../../utilities/constants';
import dashboardConstants from '../../utilities/dashboard-constants';
import { mapValidationStatusTitle } from '../../utilities/map-validation-status-title';
import datasetAnalyticsQuery from '../../graphql/queries/dataset-analytics.gql';
import allRecords from '../../graphql/queries/all-records.gql';
import DashboardFilterForm from '../DashboardFilterForm';

const typeIconMap = {
  'area-chart': 'trending-up',
  'pie-chart': 'pie-chart',
};

const DASHBOARD_SAVED_LAYOUT = 'dashboard_saved_layout';

const getData = (dateField, field, interval, searchBody) => {
  const { data, loading } = useQuery(datasetAnalyticsQuery, {
    variables: {
      dateField,
      field,
      interval,
      searchBody,
    },
  });
  const datasetAnalytics = get(data, 'datasetAnalytics', {
    data: [],
    total: 0,
  });

  const { data: records } = useQuery(allRecords, {
    variables: {
      dateField: '',
      field: 'status',
      interval: null,
      searchBody,
    },
  });

  console.log('records', records);

  return {
    data: datasetAnalytics.data,
    loading,
    total: datasetAnalytics.total,
  };
};

const AnalyticsDashboardV1Page = ({ location }) => {
  const theme = useContext(ThemeContext);
  const colors = theme.colors.visualizations;
  const { user } = useContext(AuthContext);
  const [showFilters, setShowFilters] = useState(false);

  // const hasWritePermission = isPermitted({
  //   desiredAction: PERMISSION_ACTIONS.W,
  //   desiredResource: PERMISSION_RESOURCES.DATUMS,
  //   ...user,
  // });

  const {
    filters,
    sortCriteria,
    pageCriteria,
  } = dashboardConstants.getCriterias({
    search: location.search,
    type: 'analytics',
  });

  // const query = dashboardConstants.getQuery({
  //   filters,
  //   page: pageCriteria,
  //   sort: sortCriteria,
  //   user,
  // });

  const analyticsSearchBody = dashboardConstants.getQuery({
    filters,
    user,
  });

  const searchBody = JSON.stringify(analyticsSearchBody);
  let interval = '1M';
  JSON.parse(searchBody).map((obj) => {
    if (obj.field === 'createdAt') {
      if (obj.value[1] - obj.value[0] <= 2678399999) {
        interval = '1d';
      }
    }
    return null;
  });

  const constantDatasetTypeSearchBody = JSON.parse(searchBody);
  constantDatasetTypeSearchBody.map((object) => {
    const temp = object;
    if (temp.field === 'datasetType') {
      temp.value = 'user-uploaded';
    }
    return temp;
  });

  const analytics = {
    fileSize: getData(
      'createdAt',
      'fileSize',
      interval,
      JSON.stringify(constantDatasetTypeSearchBody)
    ),
    fileTypes: getData('', 'format', null, searchBody),
    standradizationTime: getData(
      'createdAt',
      'processingTime',
      interval,
      JSON.stringify(constantDatasetTypeSearchBody)
    ),
    validationState: getData(
      '',
      'validationResult.validationStatus',
      null,
      searchBody
    ),
  };

  analytics.validationState.data = mapValidationStatusTitle(
    analytics.validationState.data
  );

  // console.log('analytics', analytics);
  const datasetStatusDistribution = analytics.validationState.data
    ? analytics.validationState.data.map((item) => ({
        ...item,
        message: `${item.value} ${item.label} out of ${analytics.validationState.total}`,
      }))
    : [];
  const dataJson = [
    {
      graphValue: datasetStatusDistribution,
      label: 'Dataset Status Distribution',
      partners: [
        'spr:partner::5dd60ce0-448e-4520-add2-ba98f78e7b33',
        'spr:partner::cab6079d-0a2f-4a52-8f9b-a63771769b73',
      ],
      saved: false,
      total: analytics.validationState.total,
      type: 'pie-chart',
      value: 'applicationProcessingResults',
    },
    {
      graphValue: [
        { label: 'In progress', value: 435 },
        { label: 'Successful', value: 16452 },
        { label: 'Errored', value: 1909 },
      ],
      label: 'Record Status Distribution',
      partners: ['spr:partner::5dd60ce0-448e-4520-add2-ba98f78e7b33'],
      saved: false,
      total: 18796,
      type: 'pie-chart',
      value: 'recordsStatusDistribution',
    },
    {
      graphValue: [
        { label: 'Schema Verification', value: 489 },
        { label: 'Column Mapper', value: 495 },
        { label: 'Value Mapper', value: 392 },
        { label: 'Case Rule', value: 208 },
        { label: 'Enroller', value: 325 },
      ],
      label: 'Record Error Step Distribution',
      partners: [
        'spr:partner::5dd60ce0-448e-4520-add2-ba98f78e7b33',
        'spr:partner::cab6079d-0a2f-4a52-8f9b-a63771769b73',
        'spr:partner::7e045399-bdd3-4b77-9685-b7fdb362508f',
      ],
      saved: false,
      total: 1909,
      type: 'pie-chart',
      value: 'recordErrorStepDistribution',
    },
    {
      graphValue: [
        {
          label: 'SC001',
          message:
            'Schema Verification(510019::GB_CI::10YEARS_1 not found in DEX.Run.',
          value: 128,
        },
        {
          label: 'SC002',
          message: 'Could not access any Master Policy',
          value: 83,
        },
        {
          label: 'SC003',
          message: 'Could not access any Master Policy',
          value: 278,
        },
        {
          label: 'CM001',
          message: 'Details not found in destination schema',
          value: 495,
        },
        {
          label: 'VM001',
          message: 'Critical information missing',
          value: 235,
        },
        {
          label: 'VM002',
          message: 'Critical information missing',
          value: 157,
        },
        {
          label: 'CR001',
          message: 'Critical information missing',
          value: 83,
        },
        {
          label: 'CR002',
          message: 'Critical information missing',
          value: 51,
        },
        {
          label: 'CR003',
          message: 'Critical information missing',
          value: 74,
        },
        {
          label: 'EE001',
          message: 'Critical information missing',
          value: 116,
        },
        {
          label: 'EE001',
          message: 'Critical information missing',
          value: 209,
        },
      ],
      label: 'Record Error Distribution',
      partners: [
        'spr:partner::5dd60ce0-448e-4520-add2-ba98f78e7b33',
        'spr:partner::cab6079d-0a2f-4a52-8f9b-a63771769b73',
        'spr:partner::7e045399-bdd3-4b77-9685-b7fdb362508f',
        'spr:partner::65950755-99d4-42af-9bb6-14fd95a5a1d8',
        'spr:partner::628152ad-f572-42d7-8d78-1f6d7bd66a49',
      ],
      saved: false,
      total: 1909,
      type: 'pie-chart',
      value: 'recordErrorDistribution',
    },
    {
      graphValue: [
        { label: moment().subtract(5, 'minutes').valueOf(), value: 318 },
        { label: moment().subtract(10, 'minutes').valueOf(), value: 4328 },
        {
          label: moment().subtract(15, 'minutes').valueOf(),
          value: 10812,
        },
        { label: moment().subtract(20, 'minutes').valueOf(), value: 962 },
        { label: moment().subtract(25, 'minutes').valueOf(), value: 23 },
        { label: moment().subtract(30, 'minutes').valueOf(), value: 9 },
      ],
      label: 'Record Execution Time',
      partners: [
        'spr:partner::5dd60ce0-448e-4520-add2-ba98f78e7b33',
        'spr:partner::cab6079d-0a2f-4a52-8f9b-a63771769b73',
        'spr:partner::7e045399-bdd3-4b77-9685-b7fdb362508f',
        'spr:partner::65950755-99d4-42af-9bb6-14fd95a5a1d8',
        'spr:partner::628152ad-f572-42d7-8d78-1f6d7bd66a49',
      ],
      saved: false,
      type: 'area-chart',
      value: 'recordExecutionTime',
    },
    {
      graphValue: [
        { label: moment().subtract(1, 'day').valueOf(), value: 147 },
        { label: moment().subtract(2, 'day').valueOf(), value: 381 },
        { label: moment().subtract(3, 'day').valueOf(), value: 812 },
        { label: moment().subtract(4, 'day').valueOf(), value: 231 },
        { label: moment().subtract(5, 'day').valueOf(), value: 188 },
        { label: moment().subtract(10, 'day').valueOf(), value: 284 },
        { label: moment().subtract(15, 'day').valueOf(), value: 35 },
        { label: moment().subtract(20, 'day').valueOf(), value: 19 },
      ],
      label: 'Errored Records Resolution Time',
      partners: [
        'spr:partner::5dd60ce0-448e-4520-add2-ba98f78e7b33',
        'spr:partner::65950755-99d4-42af-9bb6-14fd95a5a1d8',
        'spr:partner::628152ad-f572-42d7-8d78-1f6d7bd66a49',
      ],
      saved: false,
      type: 'area-chart',
      value: 'agingReports',
    },
    {
      graphValue: [
        { label: moment().subtract(1, 'day').valueOf(), value: 10 },
        { label: moment().subtract(2, 'day').valueOf(), value: 3000 },
        { label: moment().subtract(3, 'day').valueOf(), value: 1500 },
        { label: moment().subtract(4, 'day').valueOf(), value: 2300 },
        { label: moment().subtract(5, 'day').valueOf(), value: 4000 },
        { label: moment().subtract(10, 'day').valueOf(), value: 2200 },
        { label: moment().subtract(15, 'day').valueOf(), value: 500 },
        { label: moment().subtract(20, 'day').valueOf(), value: 4567 },
      ],
      label: 'Record Processing Timeline',
      partners: [
        'spr:partner::5dd60ce0-448e-4520-add2-ba98f78e7b33',
        'spr:partner::628152ad-f572-42d7-8d78-1f6d7bd66a49',
      ],
      saved: false,
      type: 'area-chart',
      value: 'recordProcessingTimeline',
    },
    {
      graphValue: [...analytics.standradizationTime.data],
      label: 'Dataset Processing Timeline',
      partners: [
        'spr:partner::65950755-99d4-42af-9bb6-14fd95a5a1d8',
        'spr:partner::628152ad-f572-42d7-8d78-1f6d7bd66a49',
      ],
      saved: false,
      type: 'area-chart',
      value: 'datasetProcessingTimeline ',
    },
  ];

  const userId = get(user, 'cognitoUsername');
  const userName = `${get(user, 'givenName', '')} ${get(
    user,
    'familyName',
    ''
  )}`;

  // const { data } = useQuery(allPartnerQuery, {
  //   fetchPolicy: 'network-only',
  // });
  // const partnerList = tradingPartners(get(data, 'allPartner', []), user);

  const currentLayoutQuery = useQuery(allViewsQuery, {
    fetchPolicy: 'no-cache',
    skip: !userId,
    variables: {
      type: 'home-page',
      userId,
    },
  });

  const currentLayoutData = JSON.parse(
    get(currentLayoutQuery, 'data.allViews', '[]')
  );

  const allGraphsQueryResult = useQuery(allGraphsQuery, {
    skip: !userId,
  });
  // const allViews = get(allGraphsQueryResult, 'data.allGraphs', []);

  const loading =
    get(currentLayoutQuery, 'loading', true) ||
    get(allGraphsQueryResult, 'loading', true);

  const layoutExists = currentLayoutData.length > 0;
  const currentLayoutCriteria = get(
    currentLayoutData[currentLayoutData.length - 1],
    'filterCriteria',
    {}
  );

  let selectedGraphs = [];
  const defaultGraphs = localStorage.getItem(DASHBOARD_SAVED_LAYOUT)
    ? JSON.parse(localStorage.getItem(DASHBOARD_SAVED_LAYOUT))
    : [];

  const initialValues = layoutExists
    ? {
        filterCriteria: currentLayoutCriteria,
      }
    : {
        filterCriteria: {
          hp_graphs: defaultGraphs.map((g) => ({
            viewId: g,
          })),
          hp_timeRange: 'days_1',
        },
        id: '',
      };

  const graphBoxSx = {
    height: 'calc(350px + 0.75rem)',
    minHeight: 'calc(350px + 0.75rem)',
    minWidth: 350,
    p: 5,
  };

  return (
    <Route
      header={{
        rightContainer: (
          <ButtonGroups>
            <Form
              initialValues={{ hp_timeRange: 'days_1' }}
              onSubmit={noop}
              render={() => (
                <Field
                  component={Dropdown}
                  inputWrapperSx={{
                    minWidth: '180px',
                  }}
                  label="Time Frame"
                  labelSx={{
                    fontSize: 2,
                    fontWeight: 'bold',
                    mx: 4,
                  }}
                  name="hp_timeRange"
                  options={[
                    { label: 'Last 1 day', value: 'days_1' },
                    { label: 'Last 2 days', value: 'days_2' },
                    { label: 'Last 3 days', value: 'days_3' },
                    { label: 'Last 14 days', value: 'days_14' },
                    { label: 'Last 30 days', value: 'days_30' },
                  ]}
                  wrapperSx={{
                    alignItems: 'center',
                    display: 'flex',
                    m: 0,
                    mx: 4,
                  }}
                />
              )}
            />
            <ToolbarButton
              active={showFilters}
              icon="filter"
              label="Filters"
              onClick={() => setShowFilters(!showFilters)}
            />
            {/* <ToolbarButton
              disabled={!layoutChanged}
              icon="lists"
              label="Save Layout"
              onClick={async () => {
                // localStorage.setItem(
                //   DASHBOARD_SAVED_LAYOUT,
                //   JSON.stringify(selectedGraphs)
                // );
                // const filterCriteria = get(values, 'filterCriteria', {});
                // const id = get(values, 'gId', '');
                // try {
                //   await updateView({
                //     variables: {
                //       description: `v1`,
                //       filterCriteria: JSON.stringify(filterCriteria),
                //       mode: 'create',
                //       type: 'home-page',
                //       userId,
                //     },
                //   });
                //   setSnack('Layout Updated');
                // } catch (e) {
                //   setErrorSnack(e.message);
                // }
              }}
            /> */}
          </ButtonGroups>
        ),
        title: userName,
        type: 'home',
        typeLabel: `Hello!`,
      }}
      isPrivate
    >
      {/* {loading ? (
        <Spinner />
      ) : ( */}
      <Form
        initialValues={initialValues}
        keepDirtyOnReinitialize
        mutators={{
          ...arrayMutators,
        }}
        onSubmit={noop}
        render={({
          form: {
            mutators: { push },
          },
          values,
        }) => {
          // const graphParams = {
          //   businessUnits,
          //   timeRange: get(values, 'filterCriteria.hp_timeRange'),
          //   userId,
          // };
          const graphs = get(values, 'filterCriteria.hp_graphs', []);
          const graphNames = graphs
            .filter((i) => i)
            .map((i) => i.viewId || null)
            .filter((i) => i);
          // const isChanged =
          //   graphNames.length !== defaultGraphs.length ||
          //   graphNames.every((i) => !defaultGraphs.includes(i));
          // setLayoutChanged(isChanged);
          selectedGraphs = graphNames;
          const graphWidth =
            graphs.length + 1 >= 3
              ? '33.33%'
              : `calc(100%/${graphs.length + 1})`;

          localStorage.setItem(
            DASHBOARD_SAVED_LAYOUT,
            JSON.stringify(selectedGraphs)
          );

          const filteredData = [...dataJson];
          // const byPartnerQuery = get(values, 'partner', []).toString();
          // filteredData = byPartnerQuery
          //   ? dataJson
          //       .filter(
          //         (data) =>
          //           data.partners &&
          //           data.partners.find((i) => byPartnerQuery.includes(i))
          //       )
          //       .filter((i) => i)
          //   : dataJson;

          return (
            <>
              {showFilters && (
                <DashboardFilterForm
                  filters={filters}
                  page={pageCriteria}
                  showFilters={showFilters}
                  sort={sortCriteria}
                />
              )}
              {/* {showFilters && (
                  <Box
                    sx={{
                      alignItems: 'flex-end',
                      bg: 'grays.2',
                      borderRadius: 2,
                      display: 'flex',
                      flexWrap: 'wrap',
                      fontSize: 2,
                      justifyContent: 'flex-start',
                      mb: 5,
                      p: 5,
                    }}
                  >
                    <Box sx={{ p: 4, width: 'calc(100% / 4)' }}>
                      <Field
                        component={MultiSelectDropdown}
                        data-cy="partner"
                        label="Partner Name"
                        name="partner"
                        options={[...partnerList].map((partner) => ({
                          label: partner.partnerName,
                          value: partner.id,
                        }))}
                      />
                    </Box>
                    <Box sx={{ p: 4, width: 'calc(100% / 4)' }}>
                      <Field
                        component={DatePicker}
                        label="Date range"
                        labelSx={{
                          fontSize: 1,
                        }}
                        name="Date range"
                      />
                    </Box>
                    <Box sx={{ p: 4, width: 'calc(100% / 4)' }}>
                      <Field
                        component={MultiSelectDropdown}
                        data-cy="processStatus"
                        label="Process Status"
                        name="processStatus"
                        options={[
                          {
                            label: 'Success',
                            value: 'success',
                          },
                          {
                            label: 'In Process',
                            value: 'inProcess',
                          },
                          {
                            label: 'Failed',
                            value: 'failed',
                          },
                        ]}
                      />
                    </Box>
                    <Box sx={{ p: 4, width: 'calc(100% / 4)' }}>
                      <Field
                        component={MultiSelectDropdown}
                        data-cy="tags"
                        label="tags"
                        name="tags"
                        options={[
                          {
                            label: 'partner:machine-shop',
                            value: 'partner:machine-shop',
                          },
                          {
                            label: 'RunType:automation',
                            value: 'RunType:automation',
                          },
                        ]}
                      />
                    </Box>
                  </Box>
                )} */}

              <FieldArray name="filterCriteria.hp_graphs">
                {({ fields }) => {
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        m: -5,
                      }}
                    >
                      {fields.map((name, index) => {
                        const graphName = get(values, `${name}.viewId`);
                        const graph = filteredData.find(
                          (i) => i.value === graphName
                        );
                        const remainingGraphs = selectedGraphs.filter(
                          (i) => i !== graphName
                        );

                        return (
                          <Box
                            key={`graph-${index}`}
                            sx={{
                              display: 'flex',
                              width: graphWidth,
                              ...graphBoxSx,
                            }}
                          >
                            <Box
                              sx={{
                                bg: 'white',
                                borderRadius: 2,
                                height: '100%',
                                pb: 2,
                                pt: 5,
                                px: 5,
                                width: '100%',
                              }}
                            >
                              <GraphToolbar
                                data={filteredData
                                  .filter(
                                    (item) =>
                                      !remainingGraphs.includes(item.value)
                                  )
                                  .map(({ label, type, value }) => {
                                    return {
                                      label: (
                                        <Box
                                          sx={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              alignItems: 'center',
                                              color: 'accentDark',
                                              mx: 2,
                                            }}
                                          >
                                            <Icon
                                              height="1rem"
                                              svg={get(
                                                typeIconMap,
                                                type,
                                                'help'
                                              )}
                                              width="1rem"
                                            />
                                          </Box>
                                          <Box
                                            sx={{
                                              alignItems: 'center',
                                              mx: 2,
                                            }}
                                          >
                                            {label}
                                          </Box>
                                        </Box>
                                      ),
                                      value,
                                    };
                                  })}
                                fields={fields}
                                index={index}
                                name={name}
                              />
                              {graph ? (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '80%',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      alignItems: 'center',
                                      borderRadius: 2,
                                      height: '76%',
                                      my: '2%',
                                      position: 'relative',
                                      textAlign: 'center',
                                      width: '100%',
                                    }}
                                  >
                                    <>
                                      {graph.type === 'pie-chart' && (
                                        <PieChart
                                          data={graph.graphValue}
                                          legend
                                          loading={loading}
                                          // showPercentage={false}
                                          total={graph.total}
                                        />
                                      )}
                                      {graph.type === 'area-chart' && (
                                        <>
                                          {graph.value ===
                                          'recordExecutionTime' ? (
                                            <>
                                              <AreaGraph
                                                areaFillColor={
                                                  graph.value ===
                                                  'recordExecutionTime'
                                                    ? colors[1]
                                                    : colors[0]
                                                }
                                                bisector={
                                                  bisector((d) => d.label).left
                                                }
                                                dotColor={colors[3]}
                                                formatTooltip={(
                                                  date,
                                                  yAccessorValue
                                                ) => (
                                                  <Box textAlign="left">
                                                    <Box as="h5">{date}</Box>
                                                    <Box
                                                      as="h5"
                                                      sx={{
                                                        color: colors[0],
                                                      }}
                                                    >
                                                      {yAccessorValue}
                                                    </Box>
                                                  </Box>
                                                )}
                                                lineColor={colors[3]}
                                                loading={loading}
                                                mainData={graph.graphValue}
                                                margin={{
                                                  bottom: 20,
                                                  left: 10,
                                                  right: 10,
                                                  top: 20,
                                                }}
                                                suffixData={[]}
                                                xAccessor={(d) => d.label}
                                                xFormatter={{
                                                  day: 'numeric',
                                                  hour: 'numeric',
                                                  minute: 'numeric',
                                                  month: 'short',
                                                  year: 'numeric',
                                                }}
                                                xLabel="Minute"
                                                yAccessor={(d) => d.value}
                                                yLabel="Number of Records"
                                              />
                                            </>
                                          ) : (
                                            <AreaGraph
                                              areaFillColor={colors[1]}
                                              bisector={
                                                bisector((d) => d.label).left
                                              }
                                              dotColor={colors[3]}
                                              formatTooltip={(
                                                date,
                                                duration
                                              ) => (
                                                <Box textAlign="left">
                                                  <Box as="h5">{date}</Box>
                                                  <Box
                                                    as="h5"
                                                    sx={{
                                                      color: colors[1],
                                                    }}
                                                  >
                                                    {parseInt(duration, 10) >=
                                                    1000
                                                      ? formatDuration(
                                                          parseInt(duration, 10)
                                                        )
                                                      : `${duration.toFixed(
                                                          3
                                                        )} milliseconds`}
                                                  </Box>
                                                </Box>
                                              )}
                                              lineColor={colors[3]}
                                              loading={loading}
                                              mainData={graph.graphValue}
                                              margin={{
                                                bottom: 20,
                                                left: 10,
                                                right: 10,
                                                top: 20,
                                              }}
                                              suffixData={[]}
                                              xAccessor={(d) => d.label}
                                              xFormatter={
                                                interval === '1M'
                                                  ? {
                                                      month: 'short',
                                                      year: 'numeric',
                                                    }
                                                  : {
                                                      day: 'numeric',
                                                      month: 'short',
                                                      year: 'numeric',
                                                    }
                                              }
                                              xLabel="Day"
                                              yAccessor={(d) => d.value}
                                              yLabel="Number of Records"
                                            />
                                          )}
                                        </>
                                      )}
                                    </>
                                  </Box>
                                  <Button
                                    link={`/datasets/${location.search}`}
                                    simple
                                    sx={{
                                      display: 'flex',
                                      ml: 'auto',
                                      mr: 0,
                                      mt: 'auto',
                                    }}
                                    variant="buttons.link"
                                  >
                                    Details{' '}
                                    <Icon
                                      svg="right-chevron"
                                      sx={{ ml: '.5rem', width: '.5rem' }}
                                    />
                                  </Button>
                                </Box>
                              ) : (
                                <LoginIllustration height="80%" width="auto" />
                              )}
                            </Box>
                          </Box>
                        );
                      })}
                      {get(values, 'filterCriteria.hp_graphs', []).length <
                        filteredData.length && (
                        <Box
                          key="add-new-graph"
                          sx={{
                            width: graphWidth,
                            ...graphBoxSx,
                          }}
                        >
                          <Box
                            sx={{
                              '&:hover': {
                                bg: 'grays.2',
                              },
                              alignItems: 'center',
                              bg: 'none',
                              borderColor: 'grays.3',
                              borderRadius: 2,
                              borderStyle: 'dashed',
                              borderWidth: 2,
                              color: 'grays.3',
                              cursor: 'pointer',
                              display: 'flex',
                              height: '100%',
                              justifyContent: 'center',
                              p: 5,
                              width: '100%',
                            }}
                          >
                            <ToolbarButton
                              bg="grays.4"
                              icon="plus"
                              label="Add Graph"
                              onClick={() =>
                                push('filterCriteria.hp_graphs', {})
                              }
                            />
                          </Box>
                        </Box>
                      )}
                    </Box>
                  );
                }}
              </FieldArray>
            </>
          );
        }}
      />
      {/* )} */}
    </Route>
  );
};

AnalyticsDashboardV1Page.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

export default AnalyticsDashboardV1Page;
