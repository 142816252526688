import React from 'react';
import { Router } from '@reach/router';
import NotFoundPage from './404';
import AnalyticsDashboardPage from '../../components/AnalyticsDashboardPage';
import AnalyticsDashboardV1Page from '../../components/AnalyticsDashboardV1Page';
import AnalyticsDashboardDetailsPage from '../../components/AnalyticsDashboardDetailsPage';

const AnalyticsDashboardRouter = (props) => (
  <Router>
    <AnalyticsDashboardDetailsPage {...props} path="/analytics/:datasetId" />
    <AnalyticsDashboardV1Page {...props} path="/analytics/v1" />
    <AnalyticsDashboardPage {...props} path="/analytics/" />
    <NotFoundPage default />
  </Router>
);

export default AnalyticsDashboardRouter;
