import Box from '@shoreag/base/Box';
import PropTypes from 'prop-types';
import React from 'react';

const TableWrapper = ({ sx, ...rest }) => (
  <Box
    sx={{
      bg: 'grays.0',
      borderRadius: 2,
      mt: 5,
      overflow: 'auto',
      ...sx,
    }}
    {...rest}
  />
);

TableWrapper.propTypes = {
  sx: PropTypes.shape({}),
};

TableWrapper.defaultProps = {
  sx: {},
};

export default TableWrapper;
